interface ILabeledDescriptionProps {
  icon?: JSX.Element;
  description: string;
  useChildVariant?: boolean;
  children?: React.ReactNode;
}

const LabeledDescription: React.FC<ILabeledDescriptionProps> = ({
  icon,
  description,
  useChildVariant,
  children,
}) => {
  return (
    <div className="flex flex-row items-center py-3 w-fit">
      {useChildVariant ? children : icon}
      <div
        className={
          useChildVariant
            ? 'pl-3 text-[16px] leading-snug tracking-wide font-secondary-800 font-Manrope'
            : 'pl-3'
        }
      >
        {description}
      </div>
    </div>
  );
};

export default LabeledDescription;
