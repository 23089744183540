//Extracts the resolved type of a promise
type PromiseType<T extends Promise<any>> = T extends Promise<infer R>
  ? R
  : never;

//Maps resolved promise types to object properties. If the promise was not resolved, maps undefined
type AllSettledResult<T> = {
  [K in keyof T]: T[K] extends Promise<any>
    ? PromiseType<T[K]> | undefined
    : undefined;
};

/**
   * Type-generic function that executes promise.allSettled on each parameter from an input object and automatically maps all types back to the output
   * @example
    //We have two promises that we want to await concurrently, each with its own type definiton
   * const promiseOne: Promise<PromiseOneType> = await foo();
   * const promiseTwo: Promise<PromiseTwoType> = await bar();
   * 
   * //Construct an object using these promises as its parameters
   * const promisesToResolve = {
   *    promiseOne: promiseOne,
   *    promiseTwo: promiseTwo
   * }
   * 
   * //We can now use these variables without needing to check and manually reassign types
   *  const { promiseOneResolved, promiseTwoResolved } = await this.allSettledPromisesWithTypes(promisesToResolve);
   */
export const allSettledPromisesWithTypes = async <
  T extends Record<string, Promise<any>>,
>(
  promisesToSettle: T & Record<string, Promise<any>>,
): Promise<AllSettledResult<T>> => {
  //Extract the promises from the input and await
  const results = await Promise.allSettled(Object.values(promisesToSettle));

  //Map the types back to the resolved promises. For any that failed, return as undefined
  const resolvedPromises: AllSettledResult<T> = Object.keys(
    promisesToSettle,
  ).reduce<AllSettledResult<T>>(
    (acc: AllSettledResult<T>, key: string, index: number) => {
      //grab the corresponding result for the current key
      const result = results[index];

      //If the promise fulfilled, we add the value and type to the return object
      //Otherwise we set the property as undefined
      if (result.status === 'fulfilled') {
        acc[key as keyof T] = result.value as PromiseType<T[keyof T]>;
      } else {
        acc[key as keyof T] = undefined;
      }
      return acc;
    },
    {} as AllSettledResult<T>, // Initialize the accumulator object to an empty object
  );

  return resolvedPromises;
};

/**
   * Type-generic function that accepts an object whose parameters consist of pending promises to be resolved
   * using Promise.all. Returns an object with the same structure as the input, with all types intact.

   * @example
    //Two promises that we want to await concurrently, each with its own type definiton
   * const promiseOne: Promise<PromiseOneType> = await foo();
   * const promiseTwo: Promise<PromiseTwoType> = await bar();
   * 
   * //Construct an object using these promises as its parameters
   * const promisesToResolve = {
   *    promiseOne: promiseOne,
   *    promiseTwo: promiseTwo
   * }
   * 
   * //We can now use these variables without needing to check and manually reassign types
   *  const { promiseOneResolved, promiseTwoResolved } = await this.allPromisesWithTypes(promisesToResolve);
   */
export const allPromisesWithTypes = async <
  T extends Record<string, Promise<any>>,
>(
  promisesToSettle: T & Record<string, Promise<any>>,
): Promise<AllSettledResult<T>> => {
  // Use Object.values to extract the array of promises from the input object
  const results = await Promise.all(
    Object.values(promisesToSettle).map((promise) => promise.catch((e) => e)),
  );

  //Map the types back to the resolved promises
  const resolvedPromises: AllSettledResult<T> = Object.keys(
    promisesToSettle,
  ).reduce<AllSettledResult<T>>(
    (acc: AllSettledResult<T>, key: string, index: number) => {
      // Grab the corresponding result for the current key
      const result = results[index];

      // Add the resolved value and its type to the return object
      //Otherwise we set the property as undefined
      if (!(result instanceof Error)) {
        acc[key as keyof T] = result as PromiseType<T[keyof T]>;
      } else {
        acc[key as keyof T] = undefined;
      }

      return acc;
    },
    {} as AllSettledResult<T>, // Initialize the accumulator object to an empty object
  );

  // Return the object with resolved types mapped over
  return resolvedPromises;
};
