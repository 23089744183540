import { EIPGlobalInformation } from '../../helpers/interfaces/dataTypes.interface';
import ReportContractModal from '../Footer/ReportContractModal';
import Header from '../Header/Header';
import { isFireWallet } from '../../helpers/methods';

interface ILayoutProps {
  children: React.ReactNode;
  eipGlobalInformation: EIPGlobalInformation;
}

const Layout: React.FC<ILayoutProps> = ({ children, eipGlobalInformation }) => {
  const fireWallet = isFireWallet(location.search)
  return (
    <div
      className={`${
        fireWallet ? 'h-[570px]' : 'h-screen'
      } no-scrollbar overflow-y-auto bg-[#FAF7FF]`}
    >
      <Header useHeader={!fireWallet} />
      {children}
      {!fireWallet && (
        <ReportContractModal eipGlobalInformation={eipGlobalInformation} />
      )}
    </div>
  );
};

export default Layout;
