import { ISimulationError } from '../../helpers/interfaces/dataTypes.interface';
import TopOverlay from '../Overlays/TopOverlay';
import ExitIcon from '../Shared/FireModal/ExitIcon';

interface IErrorModalProps {
  error: ISimulationError;
}

const ErrorModal = ({ ...props }: IErrorModalProps) => (
  <TopOverlay>
    <div className="h-screen bg-fire_purple-900 flex justify-center items-center">
      <div className="bg-white rounded-2xl max-w-[314px]">
        <div className="rounded-t-2xl bg-error-800 p-5">
          <div className="w-full h-1">
            <ExitIcon className="float-right" onClick={() => window.close()} />
          </div>
          <div className="w-full flex justify-center pb-1">
            {props.error?.errorIcon}
          </div>
          <div className="text-bg-fire_purple-900 text-center text-xl text-secondary-800 px-10 font-ClashDisplay font-medium">
            {props.error?.title
              ? `${props.error?.title}!`
              : 'Simulation Failed'}
          </div>
        </div>
        <div className="h-auto w-[280px] mx-auto p-7">
          <div className="grid text-center">{props.error.messageComponent}</div>
        </div>
      </div>
    </div>
  </TopOverlay>
);

export default ErrorModal;
