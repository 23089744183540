import { Popup } from 'semantic-ui-react';
import {
  getBlockExplorerUrl,
  reduceAddress,
  reduceName,
} from '../../helpers/methods';

interface IAddressProps {
  className?: string;
  overrideClassDefaults?: boolean;
  labeledAddress?: string | null | undefined;
  position?: 'top right' | 'top center';
  network: string;
  address: string;
}

const Address: React.FC<IAddressProps> = ({
  labeledAddress,
  address,
  className,
  position,
  overrideClassDefaults,
  network,
}) => {
  const formattedAddress = reduceName(labeledAddress) ?? reduceAddress(address);
  const tooltipTrigger = (
    <a
      target="_blank"
      className="fire-link whitespace-nowrap"
      href={getBlockExplorerUrl(address, network)}
    >
      {formattedAddress}
    </a>
  );

  const tooltipContent = (
    <div className="text-center">
      {labeledAddress && (
        <>
          <span>{labeledAddress}</span>
          <br />
        </>
      )}
      <span>{address}</span>
    </div>
  );

  return (
    <div
      className={`${className} ${
        !overrideClassDefaults &&
        'font-Manrope font-medium text-sm text-secondary-800'
      } `}
    >
      <Popup
        size="mini"
        position={position ? position : 'top right'}
        trigger={tooltipTrigger}
      >
        {tooltipContent}
      </Popup>
    </div>
  );
};

export default Address;
