import React, { useState, useEffect } from 'react';

interface ICurrencyContainerProps {
  usdValue: string | undefined;
  value: number | 'Infinite' | '< 0.001';
}

const CurrencyContainer: React.FC<ICurrencyContainerProps> = ({
  usdValue,
  value,
}) => {
  const [displayValue, setDisplayValue] = useState<number | string>(value);

  useEffect(() => {
    const MAX_LENGTH = 8;

    const roundToTenth = (num: number) => Math.round(num * 10) / 10;
    const abbreviateValue = (num: number) => {
      if (num >= 1000000000000)
        return `~${roundToTenth(num / 1000000000000)} Trillion`;
      if (num >= 1000000000)
        return `~${roundToTenth(num / 1000000000)} Billion`;
      if (num >= 1000000) return `~${roundToTenth(num / 1000000)}Million`;
      if (num >= 1000) return `~${roundToTenth(num / 1000)}k`;
      return num;
    };

    if (typeof value === 'number') {
      let formattedValue =
        value.toString().length > MAX_LENGTH ? abbreviateValue(value) : value;
      if (formattedValue.toString().length > 16) {
        formattedValue = value.toString().substring(0, MAX_LENGTH - 1) + '…';
      }
      setDisplayValue(formattedValue);
    } else {
      setDisplayValue(value);
    }
  }, [value]);

  return (
    <div className="w-[125px] h-[56px] bg-gray-200 rounded">
      <div className="flex flex-col font-ClashDisplay p-2 text-right">
        <div
          className={`${
            !usdValue && 'mt-2.5'
          } text-xl font-medium text-secondary-800 overflow-hidden`}
        >
          {usdValue ?? displayValue}
        </div>
        {usdValue && (
          <div className={`text-lg text-secondary-800`}>{displayValue}</div>
        )}
      </div>
    </div>
  );
};

export default CurrencyContainer;
