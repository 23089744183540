import { getFullURL } from './config';

export const captureGenericEvent = async (
  downloadId: string,
  name: string,
  body: object
): Promise<number | undefined> => {
  try {
    const res = await fetch(getFullURL(`events/generic-event/${downloadId}`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        ...body,
      }),
    });

    return res.status;
  } catch (e) {
    console.error('Error Caught:', e);
    return undefined;
  }
};
