import { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import ExitIcon from './ExitIcon';

interface IFireModalProps {
  children: JSX.Element;
  modalContent: JSX.Element;
  height?: number;
  width?: number;
  onClose?: () => any;
  onOpen?: () => any;
  openModal?: boolean;
}

/**
 * A standard Fire modal
 * @prop {JSX.Element} children - Wrap the modal trigger (E.g. a button, "click here" text, or something else) in the modal component
 * @prop {JSX.Element} modalContent - Create a separate component with the content you wish to display when the modal appears and pass it in here
 * @prop {number} height - (Optional) Modal height in px
 * @prop {number} width - (Optional) Modal width in px
 * @prop {() => any} onClose - (Optional) function to call when the modal exit button is clicked
 * @prop {() => any} onOpen - (Optional) function to call to open the modal
 * @prop {boolean} openModal - (Optional) Provides the option to manually open the modal without the trigger
 */
const FireModal = ({ ...props }: IFireModalProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Modal
      style={{ width: props.width, borderRadius: 12 }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={props.openModal ?? open}
      trigger={props.children}
    >
      <div>
        <div className="w-full h-10 p-5 mb-5">
          <ExitIcon
            className="float-right"
            onClick={() => {
              if (props.onClose) props.onClose();
              setOpen(false);
            }}
          />
        </div>
        {props.modalContent}
      </div>
    </Modal>
  );
};

export default FireModal;
