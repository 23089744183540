const FireLogo = () => {
  return (
    <svg
      width={'32'}
      height={'32'}
      viewBox="0 0 97 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3298 86.6301H18.3015V96.2265H27.3298V86.6301Z"
        fill="#CD2E36"
      />
      <path
        d="M36.3578 86.6301H27.3296V96.2265H36.3578V86.6301Z"
        fill="#CD2E36"
      />
      <path
        d="M45.3859 86.6301H36.3577V96.2265H45.3859V86.6301Z"
        fill="#CD2E36"
      />
      <path
        d="M54.414 86.6301H45.3857V96.2265H54.414V86.6301Z"
        fill="#CD2E36"
      />
      <path
        d="M18.3017 77.0377H9.27344V86.6342H18.3017V77.0377Z"
        fill="#CD2E36"
      />
      <path
        d="M27.3298 77.0377H18.3015V86.6342H27.3298V77.0377Z"
        fill="#F19324"
      />
      <path
        d="M36.3578 77.0377H27.3296V86.6342H36.3578V77.0377Z"
        fill="white"
      />
      <path
        d="M45.3859 77.0377H36.3577V86.6342H45.3859V77.0377Z"
        fill="white"
      />
      <path
        d="M54.414 77.0377H45.3857V86.6342H54.414V77.0377Z"
        fill="#F19324"
      />
      <path
        d="M63.4421 77.0377H54.4138V86.6342H63.4421V77.0377Z"
        fill="#CD2E36"
      />
      <path
        d="M9.2736 67.4378H0.245361V77.0343H9.2736V67.4378Z"
        fill="#CD2E36"
      />
      <path
        d="M18.3017 67.4378H9.27344V77.0343H18.3017V67.4378Z"
        fill="#F19324"
      />
      <path
        d="M27.3298 67.4378H18.3015V77.0343H27.3298V67.4378Z"
        fill="white"
      />
      <path
        d="M36.3578 67.4378H27.3296V77.0343H36.3578V67.4378Z"
        fill="white"
      />
      <path
        d="M45.3859 67.4378H36.3577V77.0343H45.3859V67.4378Z"
        fill="white"
      />
      <path d="M54.414 67.4378H45.3857V77.0343H54.414V67.4378Z" fill="white" />
      <path
        d="M63.4421 67.4378H54.4138V77.0343H63.4421V67.4378Z"
        fill="#F19324"
      />
      <path
        d="M72.4701 67.4378H63.4419V77.0343H72.4701V67.4378Z"
        fill="#CD2E36"
      />
      <path
        d="M9.2736 57.8429H0.245361V67.4394H9.2736V57.8429Z"
        fill="#CD2E36"
      />
      <path
        d="M18.3017 57.8429H9.27344V67.4394H18.3017V57.8429Z"
        fill="#F19324"
      />
      <path
        d="M27.3298 57.8429H18.3015V67.4394H27.3298V57.8429Z"
        fill="#FFB930"
      />
      <path
        d="M36.3578 57.8429H27.3296V67.4394H36.3578V57.8429Z"
        fill="white"
      />
      <path
        d="M45.3859 57.8429H36.3577V67.4394H45.3859V57.8429Z"
        fill="white"
      />
      <path
        d="M54.414 57.8429H45.3857V67.4394H54.414V57.8429Z"
        fill="#FFB930"
      />
      <path
        d="M63.4421 57.8429H54.4138V67.4394H63.4421V57.8429Z"
        fill="#F19324"
      />
      <path
        d="M72.4701 57.8429H63.4419V67.4394H72.4701V57.8429Z"
        fill="#CD2E36"
      />
      <path
        d="M18.3017 48.2456H9.27344V57.842H18.3017V48.2456Z"
        fill="#CD2E36"
      />
      <path
        d="M27.3298 48.2456H18.3015V57.842H27.3298V48.2456Z"
        fill="#F19324"
      />
      <path
        d="M36.3578 48.2456H27.3296V57.842H36.3578V48.2456Z"
        fill="#FFB930"
      />
      <path d="M45.3859 48.2456H36.3577V57.842H45.3859V48.2456Z" fill="white" />
      <path d="M54.414 48.2456H45.3857V57.842H54.414V48.2456Z" fill="#F19324" />
      <path
        d="M63.4421 48.2456H54.4138V57.842H63.4421V48.2456Z"
        fill="#CD2E36"
      />
      <path
        d="M27.3298 38.6482H18.3015V48.2446H27.3298V38.6482Z"
        fill="#CD2E36"
      />
      <path
        d="M36.3578 38.6482H27.3296V48.2446H36.3578V38.6482Z"
        fill="#F19324"
      />
      <path
        d="M45.3859 38.6482H36.3577V48.2446H45.3859V38.6482Z"
        fill="#FFB930"
      />
      <path
        d="M54.414 38.6482H45.3857V48.2446H54.414V38.6482Z"
        fill="#FFB930"
      />
      <path
        d="M63.4421 38.6482H54.4138V48.2446H63.4421V38.6482Z"
        fill="#CD2E36"
      />
      <path
        d="M27.3298 29.0533H18.3015V38.6498H27.3298V29.0533Z"
        fill="#CD2E36"
      />
      <path
        d="M36.3578 29.0533H27.3296V38.6498H36.3578V29.0533Z"
        fill="#F19324"
      />
      <path
        d="M45.3859 29.0533H36.3577V38.6498H45.3859V29.0533Z"
        fill="#CD2E36"
      />
      <path
        d="M54.414 29.0533H45.3857V38.6498H54.414V29.0533Z"
        fill="#FFB930"
      />
      <path
        d="M63.4421 29.0533H54.4138V38.6498H63.4421V29.0533Z"
        fill="#FFB930"
      />
      <path
        d="M72.4701 29.0533H63.4419V38.6498H72.4701V29.0533Z"
        fill="#CD2E36"
      />
      <path
        d="M18.3017 19.4559H9.27344V29.0524H18.3017V19.4559Z"
        fill="#CD2E36"
      />
      <path
        d="M27.3298 19.4559H18.3015V29.0524H27.3298V19.4559Z"
        fill="#F19324"
      />
      <path
        d="M36.3578 19.4559H27.3296V29.0524H36.3578V19.4559Z"
        fill="#CD2E36"
      />
      <path
        d="M54.414 19.4559H45.3857V29.0524H54.414V19.4559Z"
        fill="#FFB930"
      />
      <path
        d="M63.4421 19.4559H54.4138V29.0524H63.4421V19.4559Z"
        fill="#CD2E36"
      />
      <path
        d="M18.3017 9.86105H9.27344V19.4575H18.3017V9.86105Z"
        fill="#CD2E36"
      />
      <path
        d="M27.3298 9.86105H18.3015V19.4575H27.3298V9.86105Z"
        fill="#FFB930"
      />
      <path
        d="M36.3578 9.86105H27.3296V19.4575H36.3578V9.86105Z"
        fill="#CD2E36"
      />
      <path
        d="M54.414 9.86105H45.3857V19.4575H54.414V9.86105Z"
        fill="#CD2E36"
      />
      <path
        d="M27.3298 0.263672H18.3015V9.86012H27.3298V0.263672Z"
        fill="#CD2E36"
      />
    </svg>
  );
};
export default FireLogo;
