import { getBlockExplorerUrl } from '../../helpers/methods';
import { useAddressLabels } from '../../hooks/useAddressLabel';
import Address from '../Atoms/Address';
import CurrencyLogo from '../Atoms/CurrencyLogo';
import LinkoutButton from '../Atoms/LinkoutButton';
import TransactionRowContainer from '../Containers/TransactionRowContainer';

export interface ISpenderCardProps {
  customIcon?: JSX.Element | undefined;
  network: string;
  logo: string | undefined;
  spender: string;
  hideLinkout?: boolean;
}

const SpenderCard: React.FC<ISpenderCardProps> = ({
  customIcon,
  network,
  logo,
  spender,
  hideLinkout = false,
}) => {
  const { data: addressLabelMap, loading } = useAddressLabels(
    [spender],
    network
  );

  return (
    <TransactionRowContainer loading={loading}>
      <div className="flex flex-row items-center justify-between h-full">
        <div className="flex flex-row items-center space-x-2">
          {customIcon ? (
            customIcon
          ) : logo && logo.length ? (
            <img
              className="h-[32px] w-[32px] mr-1.5 rounded-full"
              src={logo}
              alt=""
            />
          ) : (
            <CurrencyLogo
              className="h-[32px] w-[32px] mr-1.5 rounded-full"
              type="revokal"
              searchType="name"
              identifier={addressLabelMap?.get(spender)}
            />
          )}
          <Address
            className="ml-1 overflow-hidden whitespace-nowrap text-overflow-ellipsis font-ClashDisplay text-xl font-medium"
            overrideClassDefaults={true}
            labeledAddress={addressLabelMap?.get(spender)}
            address={spender}
            network={network}
          />
        </div>
        {!hideLinkout && (
          <LinkoutButton
            className="mr-4"
            onClick={() => window.open(getBlockExplorerUrl(spender, network))}
          />
        )}
      </div>
    </TransactionRowContainer>
  );
};

export default SpenderCard;
