const TransactionDivider = () => {
  return (
    <div className="relative z-0 mx-auto w-[332px] h-[25px] flex items-center">
      <div className="w-full h-[1px] bg-gray-300" />
      <svg
        className="absolute z-0 top-1/2 transform -translate-y-1/2 left-[154px]"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12.5" cy="12.5" r="12.5" fill="white" />
        <path
          d="M11.9697 19.5303C12.2626 19.8232 12.7374 19.8232 13.0303 19.5303L17.8033 14.7574C18.0962 14.4645 18.0962 13.9896 17.8033 13.6967C17.5104 13.4038 17.0355 13.4038 16.7426 13.6967L12.5 17.9393L8.25736 13.6967C7.96447 13.4038 7.48959 13.4038 7.1967 13.6967C6.90381 13.9896 6.90381 14.4645 7.1967 14.7574L11.9697 19.5303ZM11.75 6L11.75 19L13.25 19L13.25 6L11.75 6Z"
          fill="#312E45"
        />
      </svg>
    </div>
  );
};

export default TransactionDivider;
