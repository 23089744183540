import { useState } from 'react';
import { CheckmarkIcon } from '../../helpers/icons/icons';
import { EIPGlobalInformation } from '../../helpers/interfaces/dataTypes.interface';
import ReportForm from './ReportForm';
import ShareSimulation from './ShareSimulation';

interface IReportContractModalContentProps {
  txDetail: EIPGlobalInformation;
}

const ReportContractModalContent = ({
  txDetail,
}: IReportContractModalContentProps) => {
  const [isReported, setIsReported] = useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<string>('');

  return (
    <>
      {isReported ? (
        <ShareSimulation
          txDetail={txDetail}
          header={'Thanks for submitting a report!'}
          subHeader={'Steps to spread the word on Twitter 😎'}
          modalIcon={CheckmarkIcon}
          isScam={true}
          selectedReason={selectedReason}
        />
      ) : (
        <ReportForm
          txDetail={txDetail}
          setIsReported={setIsReported}
          setUserSelectedReason={setSelectedReason}
        />
      )}
    </>
  );
};

export default ReportContractModalContent;
