import { RightCarat } from '../../helpers/icons/icons';

interface IViewRawButtonProps {
  onClick: React.MouseEventHandler<Element>;
  disabled: boolean;
}

const ViewRawButton = ({ onClick, disabled }: IViewRawButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="flex flex-row space-x-2 content-center"
    >
      <div className="font-Manrope">View Raw</div>
      {RightCarat}
    </button>
  );
};

export default ViewRawButton;
