import { getBaseUrl } from '../../helpers/methods';
import TransactionRowContainer from '../Containers/TransactionRowContainer';

interface ISignatureCardProps {
  sourceUrl: string;
  logo: string;
}

const SignatureCard: React.FC<ISignatureCardProps> = ({ sourceUrl, logo }) => (
  <TransactionRowContainer loading={false}>
    <div className="flex flex-row items-center justify-between items-center h-full">
      <div className="flex flex-row items-center">
        <img
          className="h-[32px] w-[32px] mr-1.5 rounded-full"
          src={logo}
          alt=""
        />
        <div className="ml-1 overflow-hidden whitespace-nowrap text-overflow-ellipsis font-ClashDisplay text-xl font-medium">
          {sourceUrl ? getBaseUrl(sourceUrl) : 'Unknown'}
        </div>
      </div>
    </div>
  </TransactionRowContainer>
);

export default SignatureCard;
