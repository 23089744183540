import { useContext } from 'react';
import { buildBannerMessage } from '../../helpers/engines/transactionSummaryEngine';
import { SimulationData } from '../../helpers/interfaces/dataTypes.interface';
import {
  contractBannerColor,
  contractTextColor,
  warningIcon,
} from '../../helpers/methods';
import DomainCard from './DomainCard';
import { SimulationContext } from '../../contexts/SimulationContext';

interface IBannerProps {
  transactionSimulation: SimulationData | undefined;
  sourceUrl: string | undefined;
  network: string;
  transactionType: string;
}

const Banner: React.FC<IBannerProps> = ({
  transactionSimulation,
  network,
  sourceUrl,
  transactionType,
}) => {
  const { eipGlobalInformation } = useContext(SimulationContext);
  const isSignature = !!transactionSimulation?.signature;
  const isApproval = transactionType.includes('Approve');
  const isRevokal =
    isApproval &&
    ['0', 'none'].includes(
      `${transactionSimulation?.netBalanceChanges[0]?.value}`
    );

  //show warning banner for signatures and approvals
  const displayWarningBanner = isSignature || (isApproval && !isRevokal);
  const title = displayWarningBanner ? 'Warning' : '';
  const icon = warningIcon(displayWarningBanner);
  const backgroundColor = contractBannerColor(displayWarningBanner);
  const color = contractTextColor(displayWarningBanner);

  const message = transactionSimulation ? (
    buildBannerMessage(
      transactionType,
      transactionSimulation,
      displayWarningBanner,
      network,
      eipGlobalInformation.primaryType
    ).map((element: any, i: number) => {
      return (
        <span
          key={i}
          className={`${element.bold ? 'font-bold' : 'font-light'} ${
            element.text.includes('#') && 'break-words'
          }`}
        >
          {element.text}
        </span>
      );
    })
  ) : (
    <></>
  );

  return (
    <div className={`min-h-36 ${backgroundColor} p-4`}>
      <div
        className={`text-xl ${color} flex flex-row justify-center items-center font-ClashDisplay font-medium`}
      >
        <div>{icon}</div>
        <div>{title}</div>
      </div>
      <div
        className={`${color} dark:${color} mt-0 mb-0 font-Manrope text-center`}
      >
        {message}
      </div>
      {sourceUrl && <DomainCard sourceUrl={sourceUrl} />}
    </div>
  );
};

export default Banner;
