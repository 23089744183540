import { useContext } from 'react';
import { SimulationContext } from '../../../contexts/SimulationContext';
import { SplitNetBalanceChanges } from '../../../helpers/methods';
import TransactionDivider from '../../Atoms/TransactionDivider';
import SpenderCard from '../../Shared/SpenderCard';
import Empty from '../Transfers/Empty';
import GasEstimate from '../Transfers/GasEstimate';
import TransactionFactory from './TransactionFactory';

interface ITransactionsProps {
  netBalanceChanges: any | undefined;
  hexChainId: string;
  userAddress: string;
  transactionType: string;
}

const Transactions: React.FC<ITransactionsProps> = ({
  netBalanceChanges,
  hexChainId,
  userAddress,
  transactionType,
}) => {
  const { eipGlobalInformation, transactionSimulation } =
    useContext(SimulationContext);

  const { incomingTxs, outgoingTxs, validators } = SplitNetBalanceChanges(
    netBalanceChanges,
    userAddress
  );

  //build the incoming and outgoing transaction components
  const incoming = TransactionFactory(incomingTxs);
  const outgoing = TransactionFactory(outgoingTxs);

  //Label display logic for approvals/revokals
  const isApproval = transactionType.includes('Approve');
  const isRevoke = ['0', 'none'].includes(netBalanceChanges[0].value);
  const outgoingLabel = isApproval ? 'Asset Approvals' : 'Assets Out';
  const incomingLabel =
    isApproval && isRevoke
      ? 'Revoking From'
      : isApproval
      ? 'Approval To'
      : 'Assets In';

  //Show the divider in cases where there are assets going in and out
  //This excludes 1-sided signatures since there could be multiple counterparties on the other side of the tx
  const showDivider =
    !!incomingTxs.length ||
    (!!outgoingTxs.length &&
      !(
        incomingTxs.length > 1 ||
        (outgoingTxs.length > 1 && transactionSimulation?.signature)
      ));

  return (
    <div className="-z-10 mb-6">
      <div className="h-full w-[371px] mx-auto p-4">
        {/* If no balance changes and just gas show the empty view */}
        {!incomingTxs.length && !outgoingTxs.length && <Empty />}
        {/* If outgoing txs > 1, show the regular view */}
        {!!outgoingTxs.length && (
          <>
            <div className="mb-3">
              <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
                {outgoingLabel}
              </div>
              {outgoing}
            </div>
          </>
        )}
        {/* If no outgoing txns, show counterparty card with details on who txferred the asset to you */}
        {incomingTxs.length === 1 && !outgoingTxs.length && (
          <div className="mb-4">
            <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
              From
            </div>
            <SpenderCard
              network={hexChainId}
              logo={undefined}
              spender={
                incomingTxs[0]?.from ??
                eipGlobalInformation?.contractAddress ??
                'unknown'
              }
            />
          </div>
        )}
        {/* If we have transactions going in or out, show the divider */}
        {showDivider && <TransactionDivider />}
        {/* If no incoming txns, show counterparty card with details on who the recipient is */}
        {(outgoingTxs.length === 1 && !incomingTxs.length) ||
        ['erc721Approve', 'erc20Approve', 'erc1155Approve'].includes(
          transactionType
        ) ? (
          <div className="my-3">
            <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
              {isApproval ? incomingLabel : 'Sending To'}
            </div>
            <SpenderCard
              network={hexChainId}
              logo={undefined}
              spender={
                isApproval && eipGlobalInformation.spender
                  ? eipGlobalInformation.spender
                  : outgoingTxs[0]?.to ?? 'unknown'
              }
            />
          </div>
        ) : (
          <></>
        )}
        {/* If incoming txs > 1, show the regular view */}
        {!!incomingTxs.length && (
          <>
            <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
              {incomingLabel}
            </div>
            {incoming}
          </>
        )}
        {/* If we have a gas calc, show gas estimate*/}
        {!!validators.length && (
          <GasEstimate
            className="mt-5 ml-4"
            hexChainId={hexChainId}
            usdPrice={validators[0]?.usdValue ?? -1}
            value={validators[0]?.value}
            decimals={validators[0]?.decimals}
          />
        )}
      </div>
    </div>
  );
};

export default Transactions;
