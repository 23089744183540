const SkeletonBanner = () => {
  return (
    <div className="bg-secondary-800 h-[138px] w-full p-2 flex flex-col justify-center mx-auto space-y-2">
      <div className="w-[130px] h-[24px] bg-[#665F80] rounded-lg animate-tailwindPulse mx-auto" />
      <div className="w-[336px] h-[43px] bg-[#665F80] rounded-xl animate-tailwindPulse mx-auto" />
      <div className="w-[97px] h-[24px] bg-[#665F80] rounded-full animate-tailwindPulse mx-auto" />
    </div>
  );
};

export default SkeletonBanner;
