import { useContext } from 'react';
import { version } from '../../../package.json';
import { SimulationContext } from '../../contexts/SimulationContext';
import Layout from '../Containers/Layout';
import BannerView from '../Views/BannerView';
import InteractionInfoView from '../Views/InteractionInfoView';
import WalletChangesView from '../Views/WalletChangesView';
import ScamWarningDrawer from '../Warnings/ScamWarningDrawer';

console.log('Simulator version: ', version);

const Simulator: React.FC = () => {
  const { eipGlobalInformation, transactionSimulation, transactionType } =
    useContext(SimulationContext);

  return (
    <Layout eipGlobalInformation={eipGlobalInformation}>
      <BannerView
        eipGlobalInformation={eipGlobalInformation}
        transactionSimulation={transactionSimulation}
        transactionType={transactionType}
      />
      <InteractionInfoView
        eipGlobalInformation={eipGlobalInformation}
        transactionType={transactionType}
      />
      <WalletChangesView
        eipGlobalInformation={eipGlobalInformation}
        transactionSimulation={transactionSimulation}
        transactionType={transactionType}
      />
      {eipGlobalInformation.isMaliciousAddress && <ScamWarningDrawer />}
    </Layout>
  );
};

export default Simulator;
