import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { version } from '../../package.json';
import {
  getIsMaliciousAddress,
  getSimulatedEipTransaction,
  getSimulatedSignature,
} from '../api/simulator';
import { getErrorInformation } from '../components/Error/errorHelper';
import GlobalLoader from '../components/Loader/GlobalLoader';
import { DEFAULT_EIP_GLOBAL_INFORMATION } from '../helpers/SimulationsHelper/dataDictionaries';
import {
  NETWORK_INFO,
  NetworkDecToHexMapping,
} from '../helpers/constants/application.constants';
import {
  DeepSimulationType,
  EventType,
  SignatureTypes,
} from '../helpers/enums/application.enums';
import {
  DeepSimulation,
  EIPGlobalInformation,
  SimulationData,
  TransactionSimulationRequest,
  SignatureSimulationRequest,
} from '../helpers/interfaces/dataTypes.interface';
import {
  fetchBrowserInformation,
  getAddressLabel,
  parseSpenderAddress,
} from '../helpers/methods';
import { allSettledPromisesWithTypes } from '../helpers/promiseUtil';
import { parseApprovalAddress } from '../helpers/methods';

interface ISimulationContextProps {
  eipGlobalInformation: EIPGlobalInformation;
  transactionSimulation: SimulationData | undefined;
  transactionType: string;
}

//Create global react context
const SimulationContext = React.createContext<ISimulationContextProps>(
  {} as ISimulationContextProps
);

let evtFlag = false;

interface ISimulationProviderProps {
  children: React.ReactNode;
}

//Set Global state
const SimulationProvider: React.FC<ISimulationProviderProps> = ({
  children,
}) => {
  const [eipGlobalInformation, setEIPGlobalInformation] =
    useState<EIPGlobalInformation>(DEFAULT_EIP_GLOBAL_INFORMATION);
  const [transactionType, setTransactionType] = useState('');

  const [transactionSimulation, setTransactionSimulation] =
    useState<SimulationData>();

  /**
   * Update global transation information
   */
  const updateEIPInformation = async (value: Partial<EIPGlobalInformation>) => {
    return setEIPGlobalInformation((prevState) => ({
      ...prevState,
      ...value,
    }));
  };

  const searchParams = new URLSearchParams(location.search);

  const rpcPayload = searchParams.get('rpcPayload') || '{}';
  const parsedJson: TransactionSimulationRequest = JSON.parse(rpcPayload);

  //dev
  console.log('searchParams.', rpcPayload);

  useEffect(() => {
    if (rpcPayload !== '{}') {
      iframeTransactionSimulation(parsedJson);
    }
  }, []);

  //TODO: When finished, this will use the new simpleSimulation endpoint
  const iframeTransactionSimulation = async (
    payload: TransactionSimulationRequest
  ) => {
    updateEIPInformation({
      isFireWalletSimulation: payload.walletProvider === 'Fire Wallet',
      payload: payload,
      rawData:
        payload.transaction?.data ||
        JSON.stringify(payload.data, null, 2) ||
        undefined,
    });
    const simulationResult = await getTransactionSimulation(payload);
    return simulationResult;
  };

  /**
   * Listen to ETH events
   */
  window.addEventListener('RecieveContent', function (evt: any) {
    updateEIPInformation({ loading: true });
    const evtType = typeof evt.detail;
    let parsedJson;

    if (evtType === 'string') {
      parsedJson = JSON.parse(evt.detail);
    } else {
      parsedJson = evt.detail; //Handle legacy extension versions which did not serialize to JSON
    }
    if (!evtFlag) {
      evtFlag = true;
      getTransactionSimulation(parsedJson?.data);
    }
    setSimulationCount();
  });

  const setSimulationCount = () => {
    const simulationCount = parseInt(
      localStorage.getItem('simulationCount') || '0'
    );
    localStorage.setItem('simulationCount', String(simulationCount + 1));
  };

  /**
   * Call API to simulate transaction
   */
  const getTransactionSimulation = async (
    payload: TransactionSimulationRequest
  ) => {
    try {
      updateEIPInformation({
        isFireWalletSimulation: payload.walletProvider === 'Fire Wallet',
        extensionPinned: payload.extensionPinned,
        payload: payload,
        rawData:
          payload.transaction?.data ||
          JSON.stringify(payload.data, null, 2) ||
          undefined,
      });
      let response;

      const browser = await fetchBrowserInformation();
      updateEIPInformation({ browser: browser });
      const signatureTypes = Object.values(SignatureTypes);
      if (signatureTypes.includes(payload?.type as any)) {
        const spender = parseSpenderAddress(JSON.stringify(payload));
        updateEIPInformation({ spender: spender });
        if (spender) {
          const maliciousAddressResponse = await axios(
            getIsMaliciousAddress(spender)
          );

          updateEIPInformation({
            isMaliciousAddress: maliciousAddressResponse?.data?.isMalicious,
          });
        } else {
          updateEIPInformation({ isMaliciousAddress: false });
        }

        setTransactionType(payload?.type || 'unknown');
        updateEIPInformation({
          type: payload.type,
          loading: false,
          rawData:
            payload.transaction?.data ||
            JSON.stringify(payload.data, null, 2) ||
            undefined,
          walletProvider: payload.walletProvider,
          downloadId: payload.downloadId,
          downloadUrl: payload.downloadUrl,
          sourceUrl: payload.sourceUrl,
          isFireWalletSimulation: payload.walletProvider === 'Fire Wallet',
        });

        return;
      } else if (payload.type === 'signature') {
        //Due to weird firefox issue, need to check if network is undefined and default it to mainnet. BE doesn't care about this value anyway
        if (!payload?.data.network) {
          payload.data.network =
            NetworkDecToHexMapping.get(
              Number.parseInt(payload?.data?.domain?.chainId)
            ) ?? '0x1';
        }
        updateEIPInformation({
          primaryType: payload.data.primaryType,
        });

        const spender = parseSpenderAddress(JSON.stringify(payload));
        const signatureSimulationData: SignatureSimulationRequest = {
          types: payload.data.types,
          primaryType: payload.data.primaryType,
          domain: payload.data.domain,
          message: payload.data.message,
          fromAddress: spender,
          network: payload.data.network,
          walletProvider: payload.walletProvider || 'unknown',
          sourceUrl: payload.sourceUrl || 'unknown',
          event: {
            name: EventType.START_SIMULATION,
            version: version,
          },
          browser: browser || 'unknown',
          downloadId: payload.downloadId || 'unknown',
          downloadUrl: payload.downloadUrl || 'unknown',
          extensionPinned: payload.extensionPinned,
          simulationType: 'signature',
        };

        const isMaliciousAddressPromise = !!spender
          ? axios(getIsMaliciousAddress(spender))
          : Promise.resolve({ data: { isMalicious: false } });
        const simulationPromise = axios(
          getSimulatedSignature(signatureSimulationData)
        );

        const settledPromises = await allSettledPromisesWithTypes({
          isMaliciousAddressPromise,
          simulationPromise,
        });

        response = settledPromises.simulationPromise || {};

        updateEIPInformation({
          isMaliciousAddress:
            settledPromises.isMaliciousAddressPromise?.data?.isMalicious,
        });

        if (
          ['development', 'staging'].includes(
            process.env.REACT_APP_STAGE ?? ''
          ) ||
          window.location.href.includes('staging')
        ) {
          console.log('debugging: logging API response --> ', response);
        }
      } else {
        const transaction = payload.transaction;

        const transactionEventData: any = {
          network: payload?.network ?? '0x1',
          value: transaction?.value || '0',
          gas: transaction.gas,
          from: transaction.from,
          to: transaction.to,
          data: transaction.data,
          walletProvider: payload.walletProvider || 'unknown',
          sourceUrl: payload.sourceUrl || 'unknown',
          event: {
            name: EventType.START_SIMULATION,
            version: version,
          },
          browser: browser || 'unknown',
          downloadId: payload.downloadId || 'unknown',
          downloadUrl: payload.downloadUrl || 'unknown',
          extensionPinned: payload.extensionPinned,
        };

        if (transaction?.from && transaction?.to) {
          const ensPromises = new Map<string, Promise<string | null>>([
            [
              transaction.from,
              getAddressLabel(transaction.from, payload?.network ?? '0x1'),
            ],
            [
              transaction.to,
              getAddressLabel(transaction.to, payload?.network ?? '0x1'),
            ],
          ]);
          updateEIPInformation({ addressLabels: ensPromises });
        } else {
          updateEIPInformation({ addressLabels: undefined });
        }

        const approvalAddress = parseApprovalAddress(transaction.data);

        //Return pending promises
        const isMaliciousAddressPromise = transaction?.to
          ? axios(getIsMaliciousAddress(transaction.to))
          : Promise.resolve({ data: { isMalicious: false } });

        const isMaliciousApprovalPromise = approvalAddress
          ? axios(getIsMaliciousAddress(approvalAddress))
          : Promise.resolve({ data: { isMalicious: false } });

        const simulationPromise = axios(
          getSimulatedEipTransaction(transactionEventData)
        );

        //Wait for all promises to settle
        const settledPromises = await allSettledPromisesWithTypes({
          isMaliciousAddressPromise,
          isMaliciousApprovalPromise,
          simulationPromise,
        });

        response = settledPromises.simulationPromise || {};

        updateEIPInformation({
          isMaliciousAddress:
            settledPromises.isMaliciousAddressPromise?.data?.isMalicious || settledPromises.isMaliciousApprovalPromise?.data?.isMalicious,
        });
        response = settledPromises.simulationPromise || {};

        if (
          ['development', 'staging'].includes(
            process.env.REACT_APP_STAGE ?? ''
          ) ||
          window.location.href.includes('staging')
        ) {
          console.log('debugging: logging API response --> ', response);
        }
      }

      const user: string | undefined =
        localStorage.getItem('address') || response?.data?.from;
      // Type of EIP transaction (i.e. 20 | 721)
      const eipType = response.data.type;

      updateEIPInformation({ simulationId: response.data?.id });

      // Letting the extension know the simulation id
      window.postMessage(
        {
          direction: 'from-fire-react-app',
          simulationId: response.data.id,
        },
        '*'
      );

      setTransactionType(eipType);
      updateEIPInformation({
        network: payload.network ?? payload.data.network,
        from: user,
        type: eipType,
        contractAddress: response.data.to,
        walletProvider: payload.walletProvider,
        downloadId: payload.downloadId,
        downloadUrl: payload.downloadUrl,
        sourceUrl: payload.sourceUrl,
        spender: response.data.spender,
        deepSimulationData: response.data.deepSimulationData
          ? (response.data.deepSimulationData as DeepSimulation)
          : undefined,
        rawData:
          payload.transaction?.data ||
          JSON.stringify(payload.data, null, 2) ||
          undefined,
        isFireWalletSimulation: payload.walletProvider === 'Fire Wallet',
      });

      const tokenInfo = NETWORK_INFO[payload.network || '0x1'];
      const errorInfo = getErrorInformation(response, undefined, tokenInfo);
      updateEIPInformation({ loading: false, error: errorInfo });
      setTransactionData(response.data);
    } catch (error: any) {
      console.error(error);
      const tokenInfo = NETWORK_INFO[payload.network || '0x1'];
      const errorInfo = getErrorInformation(undefined, error, tokenInfo);
      updateEIPInformation({
        loading: false,
        error: errorInfo,
      });
    }
  };

  const setTransactionData = (response: any) => {
    setTransactionSimulation(response);
    if (
      response.deepSimulationData &&
      (response.deepSimulationData as DeepSimulation).type ===
        DeepSimulationType.ENS_SIGNATURE_REQUEST
    ) {
      setTransactionType(
        response.deepSimulationData
          ? (response.deepSimulationData as DeepSimulation).type
          : response.type
      );
      return;
    }
    setTransactionType(response.type);
  };

  return (
    <SimulationContext.Provider
      value={{
        eipGlobalInformation,
        transactionSimulation,
        transactionType,
      }}
    >
      {eipGlobalInformation.loading ? <GlobalLoader /> : <>{children}</>}
    </SimulationContext.Provider>
  );
};

export { SimulationContext, SimulationProvider };
