import { SignatureTypes } from '../../helpers/enums/application.enums';
import { hexToUtf8 } from '../../helpers/methods';
import TransactionDivider from '../Atoms/TransactionDivider';
import SignatureCard from './SignatureCard';

interface ISignatureProps {
  type: SignatureTypes;
  rawData: string;
  sourceUrl: string | undefined;
}

//Only use this component with Fire Wallet payloads. It will break otherwise
const Signature: React.FC<ISignatureProps> = ({ type, rawData, sourceUrl }) => {
  //TODO: This is a hack. refactor in the future
  const parseJsonOrString = (input: string): any => {
    try {
      return JSON.parse(input);
    } catch (e) {
      return input;
    }
  };

  const parsedJson = parseJsonOrString(rawData);

  const decodePersonalSignMessage = (input: string) => {
    return hexToUtf8(parseJsonOrString(input));
  };

  //TODO: Would be cool to flag the 'spender' field if it exists in here for further validation to keep the user safe. If it is an EOA, it's probably malicious
  const parseEip712Message = () => {
    const parsedMessage = parseJsonOrString(parsedJson[1]);

    // If the message is an object, we can assume it's > v1
    if (typeof parsedMessage === 'object') {
      //Deleting the types field to make it easier to read
      delete parsedMessage.types;
      return (
        <div className="text-wrap word-break break-all">
          {JSON.stringify(parsedMessage, null, 2)}
        </div>
      );
    }
    //Otherwise go with the old format
    else {
      const signTypedDataMessage = parseJsonOrString(parsedJson[0]);
      return (
        <div className="text-wrap word-break break-all">
          {JSON.stringify(signTypedDataMessage, null, 2)}
        </div>
      );
    }
  };

  const signatureMappings: Record<
    SignatureTypes,
    {
      messageAction: string;
      counterpartyAction: string;
      decodeMessage: (input: string) => string | JSX.Element;
    }
  > = {
    personal_sign: {
      messageAction: 'Message Signing',
      counterpartyAction: 'Verifying To',
      decodeMessage: decodePersonalSignMessage,
    },
    eth_signTypedData: {
      messageAction: 'Typed Data Signing',
      counterpartyAction: 'Granting To',
      decodeMessage: parseEip712Message,
    },
    eth_signTypedData_v3: {
      messageAction: 'Typed Data Signing',
      counterpartyAction: 'Granting To',
      decodeMessage: parseEip712Message,
    },
    eth_signTypedData_v4: {
      messageAction: 'Typed Data Signing',
      counterpartyAction: 'Granting To',
      decodeMessage: parseEip712Message,
    },
    eth_sign: {
      messageAction: 'Dangerous Signature',
      counterpartyAction: 'Signing To',
      decodeMessage: (input: string) => input,
    },
    unknown_signature: {
      messageAction: 'Unknown Message',
      counterpartyAction: 'Granting To',
      decodeMessage: (input: string) => input,
    },
    eth_signTypedData_v1: {
      messageAction: 'Typed Data Signing',
      counterpartyAction: 'Granting To',
      decodeMessage: parseEip712Message,
    },
  };

  const logo =
    type === SignatureTypes.PERSONAL_SIG ? parsedJson[4] : parsedJson[3];

  return (
    <div className="mb-12 mt-5">
      <div className="font-ClashDisplay text-lg font-medium ml-10 mb-1">
        {signatureMappings[type].messageAction}
      </div>
      <div className="h-fit w-fit bg-white shadow-md mx-auto p-2 mb-4 rounded-2xl">
        <div className="font-Manrope whitespace-pre-wrap w-[315px] h-[238px] rounded-xl mx-auto bg-white text-secondary-800 overflow-auto p-3 overflow-x-hidden break-words text-normal border">
          {signatureMappings[type].decodeMessage(rawData)}
        </div>
      </div>
      <TransactionDivider />
      <div>
        <div className="font-ClashDisplay text-lg font-medium ml-10 mb-2">
          {signatureMappings[type].counterpartyAction}
        </div>
        <SignatureCard sourceUrl={sourceUrl ?? 'Unknown'} logo={logo} />
      </div>
    </div>
  );
};

export default Signature;
