const apiUrls = new Map();

if (
  process.env.REACT_APP_STAGE === 'staging' ||
  window.location.href.includes('staging') // fix for cloudflare environment variable issue
) {
  apiUrls.set('development', 'https://staging.api.services.joinfire.xyz');
  apiUrls.set('production', 'https://staging.api.services.joinfire.xyz');
} else if (
  process.env.REACT_APP_STAGE === 'production' ||
  window.location.href.includes('app.joinfire.xyz')
) {
  apiUrls.set('development', 'https://api4.services.joinfire.xyz');
  apiUrls.set('production', 'https://api4.services.joinfire.xyz');
} else if (process.env.REACT_APP_STAGE === 'development') {
  apiUrls.set('production', 'http://localhost:3001');
  apiUrls.set('development', 'http://localhost:3001');
} else {
  apiUrls.set('development', 'https://api4.services.joinfire.xyz');
  apiUrls.set('production', 'https://api4.services.joinfire.xyz');
}

export const getFullURL = (url: string) => {
  return `${apiUrls.get(process.env.NODE_ENV)}/${url}`;
};
