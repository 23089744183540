import { useContext, useEffect, useState } from 'react';
import { getReferralData } from '../api/referrals';
import { ReferralData } from '../helpers/types/referrals';
import { SimulationContext } from '../contexts/SimulationContext';

export const useReferralData = (): {
  data: ReferralData | undefined;
  loading: boolean;
  error: unknown;
} => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ReferralData>();
  const [error, setError] = useState<unknown | undefined>(undefined);
  const { eipGlobalInformation } = useContext(SimulationContext);

  useEffect(() => {
    const referral = async () => {
      try {
        const downloadId = eipGlobalInformation?.downloadId;
        if (!downloadId) throw new Error('No downloadId Returned');
        const data = await getReferralData(downloadId);
        if (!data) throw new Error('No data returned');
        setLoading(false);
        setError(undefined);
        setData(data);
      } catch (e) {
        console.error('Error Caught:', e);
        setLoading(false);
        setError(e);
        setData(data);
      }
    };
    referral();
  }, []);

  return { data, loading, error };
};
