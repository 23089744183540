import { useContext, useEffect, useState } from 'react';
import { SimulationContext } from '../../contexts/SimulationContext';
import { TwitterIcon } from '../../helpers/icons/icons';
import { NotificationReferralNthSimulation } from '../../helpers/types/events';
import { useReferralData } from '../../hooks/useReferralData';
import EmojiIcon from '../Atoms/EmojiIcon';
import ReferralCard from '../Referrals/ReferralCard';
import { SocialButton } from '../Referrals/SocialButton';
import OverlayedDrawer from '../Shared/Drawer/OverlayedDrawer';
import LabeledDescription from '../Shared/LabeledDescription';
import {
  trackClickedTwitterEvent,
  trackExitClickEvent,
  trackNotificationSeenEvent,
  urlEncode,
} from './NotificationHelper';

export const ReferralNotificationDrawer: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);

  const { data, loading, error } = useReferralData();

  const [
    notificationReferralNthSimulation,
    setNotificationReferralNthSimulation,
  ] = useState<NotificationReferralNthSimulation>({
    clicked_x: false,
    clicked_copy_link: false,
    clicked_twitter: false,
    extensionPinned: undefined,
  });

  const { eipGlobalInformation } = useContext(SimulationContext);

  const getMixpanelDefaults = () => {
    const downloadId = eipGlobalInformation?.downloadId || 'unknown';
    const extensionPinned = eipGlobalInformation?.extensionPinned;
    return { downloadId, extensionPinned };
  };

  useEffect(() => {
    const showModal = async () => {
      const simulationCount = parseInt(
        localStorage.getItem('simulationCount') || '1'
      );
      setIsOpen(
        simulationCount === 15 && !eipGlobalInformation?.isFireWalletSimulation
      );
    };
    showModal();
  }, []);

  useEffect(() => {
    if (isOpen) {
      const mixpanelDefaults = getMixpanelDefaults();
      trackNotificationSeenEvent(mixpanelDefaults);
    }
  }, [isOpen]);

  const handleClickedExit = async () => {
    setIsOpen(false);
    const mixpanelDefaults = getMixpanelDefaults();
    await trackExitClickEvent(
      notificationReferralNthSimulation,
      mixpanelDefaults
    );
  };

  const disabled = !!(loading || error);

  return (
    <OverlayedDrawer
      title={
        <h1 className="text-center font-Manrope text-secondary-800 text-[16px] font-medium">
          1 Week to Earn ETH!
        </h1>
      }
      visible={isOpen}
      setVisible={setIsOpen}
      onExitModal={handleClickedExit}
    >
      <div className="h-auto w-full p-5 pb-5 mt-3">
        <div className="mt-5 border-b border-gray-200">
          <LabeledDescription
            useChildVariant={true}
            description="Earn ETH (Up to $500) with Fire's Referral program."
          >
            <EmojiIcon emoji="💸" size="md" />
          </LabeledDescription>
          <LabeledDescription
            useChildVariant={true}
            description="Share your referral link below and start earning with Fire!"
          >
            <EmojiIcon emoji="😎" size="md" />
          </LabeledDescription>
        </div>
        <div className="mt-5">
          <ReferralCard
            data={data}
            loading={loading}
            error={error}
            onReferralButtonClick={() =>
              setNotificationReferralNthSimulation({
                ...notificationReferralNthSimulation,
                clicked_copy_link: true,
              })
            }
          />
        </div>
        <SocialButton
          className="w-[330px] mt-5 mx-auto"
          loading={loading}
          disabled={disabled}
          label="Twitter"
          url={`https://twitter.com/intent/tweet?text=I’ve been using the Fire Extension to keep my assets safe in web3. Go and download the Fire Extension through my link below. Sign up and refer others to win ETH at the end of the month:&url=${urlEncode(
            data?.referralLink ?? ''
          )}`}
          onclick={async () => {
            const mixpanelDefaults = getMixpanelDefaults();
            await trackClickedTwitterEvent(
              notificationReferralNthSimulation,
              mixpanelDefaults
            );
          }}
        >
          {TwitterIcon}
        </SocialButton>
      </div>
    </OverlayedDrawer>
  );
};
