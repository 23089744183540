import {
  NOSIM_SIGNATURE_TYPES,
  TRANSACTION_SIMULATION_TYPES,
} from '../../helpers/constants/application.constants';
import {
  DeepSimulationType,
  ErrorTypes,
  philandDeepSimulationType,
} from '../../helpers/enums/application.enums';
import {
  EIPGlobalInformation,
  SimulationData,
} from '../../helpers/interfaces/dataTypes.interface';
import Banner from '../Banner/Banner';
import GenericBanner from '../Banner/GenericBanner';
import PhilandBanner from '../Simulation/DeepSimulations/Philand/PhilandBanner';

interface IWalletChangesViewProps {
  eipGlobalInformation: EIPGlobalInformation;
  transactionSimulation: SimulationData | undefined;
  transactionType: string;
}

//Renders the necessary component for the transaction type to represent the affect of the user's wallet
const BannerView: React.FC<IWalletChangesViewProps> = ({
  eipGlobalInformation,
  transactionSimulation,
  transactionType,
}) => {
  //evaluates to true if the sim failed but we want a 'soft' error state instead of the error screen
  const simulationFailed = !!(
    eipGlobalInformation.error &&
    [ErrorTypes.NOT_VERIFIED, ErrorTypes.GENERAL_ERROR].includes(
      eipGlobalInformation.error.title
    )
  );

  //Check for deep simulation & other edge cases first
  if (
    NOSIM_SIGNATURE_TYPES.includes(transactionType as any) ||
    DeepSimulationType.ENS_SIGNATURE_REQUEST === transactionType
  ) {
    return (
      <GenericBanner
        sourceUrl={eipGlobalInformation.sourceUrl}
        header={'Signature Request'}
      />
    );
  }
  //philand claim case
  else if (
    eipGlobalInformation.deepSimulationData &&
    philandDeepSimulationType.includes(
      eipGlobalInformation.deepSimulationData.type
    )
  ) {
    return (
      <PhilandBanner
        simulationType={eipGlobalInformation.deepSimulationData.type}
        transactionSimulation={
          transactionSimulation
            ? {
                ...transactionSimulation,
                deepSimulationData: eipGlobalInformation.deepSimulationData,
              }
            : undefined
        }
        sourceUrl={eipGlobalInformation.sourceUrl}
        network={eipGlobalInformation.network}
        transactionType={transactionType}
      />
    );
  } else if (TRANSACTION_SIMULATION_TYPES.includes(transactionType as any)) {
    return (
      <Banner
        transactionSimulation={transactionSimulation}
        sourceUrl={eipGlobalInformation.sourceUrl}
        network={eipGlobalInformation.network}
        transactionType={transactionType}
      />
    );
  } //Return some general contextual details about the tx without the simulation
  else if (simulationFailed) {
    return (
      <GenericBanner
        sourceUrl={eipGlobalInformation.payload?.sourceUrl}
        header={'Confirm Request'}
      />
    );
  } else {
    return <></>;
  }
};

export default BannerView;
