import { ShareIcon } from '../../helpers/icons/icons';

interface IShareButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  iconColor: string;
}
export const ShareButton = ({
  onClick,
  className,
  iconColor,
}: IShareButtonProps) => {
  return (
    <button className={`${className}`} onClick={onClick}>
      {ShareIcon(iconColor)}
    </button>
  );
};
