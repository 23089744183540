import { DangerIcon } from '../../helpers/icons/icons';
import FireButton from '../Shared/Buttons/FireButton';
import { ShareButton } from '../Shared/ShareButton';
import { useState } from 'react';
import ShareSimulation from '../ReportContract/ShareSimulation';
import TopOverlay from '../Overlays/TopOverlay';

interface IDangerWarningProps {
  type: string;
  title: string;
  warningMessage: string;
}

/***
 * DEPRECATED--DO NOT USE!
 ** If looking for scam logic, use the ScamWarning component insteaad.
 ** This handles ETH_Sign specifically, which is deprecated by most wallets.
 ** Keeping this around just to cover the edge case where a wallet does still allow eth_sign sig reqs
 */
const DangerWarning = ({ ...props }: IDangerWarningProps) => {
  const [share, setShare] = useState<boolean>(false);

  const warningBody = (
    <>
      <div className="rounded-t-2xl bg-error-800 p-5">
        <div className="w-full h-1">
          <ShareButton
            iconColor="#312E45"
            className="float-right"
            onClick={() => setShare(true)}
          />
        </div>
        <div className="w-full flex justify-center pb-2">{DangerIcon}</div>
        <div className="text-bg-fire_purple-900 text-center text-xl px-10 font-ClashDisplay font-medium">
          {props.title}
        </div>
      </div>
      <div className="h-auto w-[280px] mx-auto p-7">
        <div className="grid text-center text-secondary-800">
          {props.warningMessage}
        </div>
      </div>
      <div className="flex flex-row mx-auto justify-center content-center space-x-3.5 mb-7 w-full">
        <FireButton
          className={'w-28 !text-base'}
          onClick={() => {
            const event = new CustomEvent('ApproveInteraction', {
              detail: {
                isOk: false,
              },
            });
            //dispatches user approval to ContentScript(FE) to inform whether or not we want to block this request
            //This event is part of the following lifecycle: inject.js --> contentScript(page) --> FE --> contentScript(FE) --> background.js --> contentScript(page) --> inject.js
            //                                                                        You are here ^^^
            window.dispatchEvent(event);
          }}
        >
          Reject
        </FireButton>
        <FireButton
          className={'w-28 !text-base'}
          onClick={() => {
            const event = new CustomEvent('ApproveInteraction', {
              detail: {
                isOk: true,
              },
            });
            //dispatches user approval to ContentScript(FE) to inform whether or not we want to block this request
            //This event is part of the following lifecycle: inject.js --> contentScript(page) --> FE --> contentScript(FE) --> background.js --> contentScript(page) --> inject.js
            //                                                                        You are here ^^^
            window.dispatchEvent(event);
          }}
        >
          Continue
        </FireButton>
      </div>
    </>
  );

  const shareSim = (
    <ShareSimulation
      txDetail={undefined}
      header={'Share to Twitter'}
      subHeader={'Steps to spread the word on Twitter 😎'}
      isScam={true}
      selectedReason={'Other'}
      useBackBtn={true}
      onBackBtnClick={() => {
        setShare(false);
      }}
    />
  );

  return (
    <TopOverlay>
      <div className="h-screen bg-fire_purple-900 flex justify-center items-center">
        <div className="bg-white rounded-2xl max-w-[314px]">
          {share ? shareSim : warningBody}
        </div>
      </div>
    </TopOverlay>
  );
};

export default DangerWarning;
