import { ScamDetail } from '../../helpers/interfaces/dataTypes.interface';
import LabeledDescription from '../Shared/LabeledDescription';
import EmojiIcon from '../Atoms/EmojiIcon';

interface IScamWarningProps {
  scamDetail: ScamDetail;
}
const ScamWarning: React.FC<IScamWarningProps> = ({
  scamDetail,
}: IScamWarningProps) => {
  return (
    <div className="h-fit w-full bg-inherit flex flex-col justify-between items-center">
      <div className="p-3.5">
        {/* This will cause a render error if scam detail arr is empty. It shouldn't be, and if it is, it will always be a dev error.
        This error case is handled and will default to the error boundary if it occurs */}
        {scamDetail.warningDetail.map((detail, index: number) => {
          const emojiIcon: React.ReactNode = (
            <EmojiIcon size="md" emoji={detail.emojiLabel} />
          );
          return (
            <div key={index} className="my-1">
              <LabeledDescription
                icon={emojiIcon}
                description={detail.description}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScamWarning;
