/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SignatureTypes } from '../../helpers/enums/application.enums';
import {
  EyeIcon,
  StopIcon,
  QuestionIcon,
  WarningIcon,
} from '../../helpers/icons/icons';
import {
  ISignatureDetail,
  ISignatureDescription,
} from '../../helpers/interfaces/dataTypes.interface';
import LabeledDescription from '../Shared/LabeledDescription';

interface ISimpleSignatureProps {
  type: SignatureTypes;
}

const SIGNATURE_COMPONENT_DETAILS = new Map<SignatureTypes, ISignatureDetail>([
  [
    SignatureTypes.PERSONAL_SIG,
    {
      isSafe: true,
      descriptionList: [
        {
          icon: EyeIcon,
          description:
            'Often used to verify your address to sign in to a dApp.',
        },
        {
          icon: StopIcon,
          description:
            'This type of signature cannot approve or transfer your funds.',
        },
      ],
    },
  ],
  [
    SignatureTypes.UNKNOWN,
    {
      isSafe: false,
      descriptionList: [
        {
          icon: QuestionIcon,
          description: "We don't recognize this signature.",
        },
        {
          icon: WarningIcon,
          description:
            'Make sure you trust this domain before signing this message.',
        },
      ],
    },
  ],
]);

const SimpleSignature: React.FC<ISimpleSignatureProps> = ({ type }) => {
  //If for some reason this errors at runtime due to the non-null assertion, we want it to
  const signatureDetail =
    SIGNATURE_COMPONENT_DETAILS.get(type) ??
    SIGNATURE_COMPONENT_DETAILS.get(SignatureTypes.UNKNOWN)!;

  return (
    <div>
      <div className="p-3">
        {signatureDetail.descriptionList.map(
          (signatureType: ISignatureDescription, index: number) => {
            return (
              <LabeledDescription
                key={index}
                icon={signatureType.icon}
                description={signatureType.description}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default SimpleSignature;
