interface ISocialButtonProps {
  label: string;
  children: React.ReactNode;
  url: string;
  loading?: boolean;
  disabled?: boolean;
  onclick?: () => Promise<void>;
  className?: string;
}

export const SocialButton: React.FC<ISocialButtonProps> = ({
  ...props
}: ISocialButtonProps) => {
  const disabled = props.disabled || props.loading;

  const onButtonClick = async () => {
    if (props.onclick) {
      await props.onclick();
    }
    window.open(props.url);
  };

  return (
    <button
      className={`w-[150px] h-[40px] bg-white shadow-md font-medium rounded-full flex flex-row justify-center items-center space-x-2 transition ease-in-out border border-zinc-700 border-opacity-20 ${
        !props.loading &&
        !props.disabled &&
        'group hover:scale-105 hover:drop-shadow-fire-orange-md'
      } duration-300 ${props.className}`}
      disabled={disabled}
      onClick={() => onButtonClick()}
    >
      {props.children}
      <div
        className={`font-ClashDisplay text-lg text-center text-secondary-800 ${
          !props.loading &&
          !props.disabled &&
          'transition ease-in-out duration-300 group-hover:text-fire_orange-200'
        }`}
      >
        {props.label}
      </div>
    </button>
  );
};
