import {
  DomainDetails,
  VerifiedDomains,
} from '../../helpers/constants/verifiedDomains';
import { VerifiedDomain } from '../../helpers/icons/icons';
import { getBaseUrl } from '../../helpers/methods';

interface IDomainCardProps {
  sourceUrl: string | undefined;
}

const DomainCard: React.FC<IDomainCardProps> = ({ sourceUrl }) => {
  const dappUrl = getBaseUrl(sourceUrl ?? '');
  const verifiedDappData: DomainDetails | undefined = VerifiedDomains[dappUrl];
  return (
    <div className="flex flex-row items-center min-w-[131px] justify-center w-fit h-[30px] py-1 px-4 rounded-full bg-white text-center mt-3 align-middle mx-auto">
      <div>{sourceUrl ? getBaseUrl(sourceUrl) : 'Unknown'}</div>
      {verifiedDappData && <div className="pl-2">{VerifiedDomain}</div>}
    </div>
  );
};

export default DomainCard;
