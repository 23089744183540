interface IWarningBannerProps {
  title: string;
  icon: JSX.Element;
}

const WarningBanner: React.FC<IWarningBannerProps> = ({
  title,
  icon,
}: IWarningBannerProps) => {
  return (
    <div
      className={`h-[128px] bg-error-400 p-4 flex justify-center items-center`}
    >
      <div
        className={`text-2xl text-secondary-800 flex flex-col justify-center items-center font-ClashDisplay font-medium`}
      >
        <div>{icon}</div>
        <div>{title}</div>
      </div>
    </div>
  );
};

export default WarningBanner;
