import { EIPGlobalInformation } from '../interfaces/dataTypes.interface';

export const DEFAULT_EIP_GLOBAL_INFORMATION: EIPGlobalInformation = {
  simulationId: '',
  network: '',
  type: '',
  from: '',
  contractAddress: '',
  transactionAction: '',
  loading: true,
  error: undefined,
  trustedContract: undefined,
  walletProvider: undefined,
  downloadId: '',
  downloadUrl: '',
  sourceUrl: undefined,
  browser: undefined,
  deepSimulationData: undefined,
  rawData: '',
  isMaliciousAddress: undefined,
};
