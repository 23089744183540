import { useContext } from 'react';
import { SimulationContext } from '../../../contexts/SimulationContext';
import ERC20Approve from './ERC20Approve';
import NftApprove from './NftApprove';

export interface ApprovalProps {
  balanceChange: any;
}

//TODO (Jordan): This can be refactored with this component being removed, with erc20 and nft approve split and selected independently by the factory
const Approval: React.FC<ApprovalProps> = ({ balanceChange }) => {
  const { eipGlobalInformation, transactionType } =
    useContext(SimulationContext);

  return transactionType === 'erc20Approve' ? (
    <ERC20Approve balanceChange={balanceChange} />
  ) : (
    <NftApprove
      balanceChange={balanceChange}
      network={eipGlobalInformation.network}
    />
  );
};

export default Approval;
