import exit from './Exit.png';

interface IExitIconProps {
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}
const ExitIcon = ({ ...props }: IExitIconProps) => {
  return (
    <button className={props.className} onClick={props.onClick}>
      <img src={exit} className={'h-[15px] w-[15px]'} />
    </button>
  );
};

export default ExitIcon;
