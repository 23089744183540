interface ITransactionCardProps {
  children: React.ReactNode;
  loading: boolean;
}

const TransactionCard: React.FC<ITransactionCardProps> = ({
  children,
  loading,
}) => {
  return (
    <div
      className={`${
        loading ? 'animate-tailwindPulse bg-gray-300' : 'shadow-md'
      } w-[332px] h-[169px] bg-white rounded-lg p-3 overflow-hidden mx-auto`}
    >
      {!loading && children}
    </div>
  );
};

export default TransactionCard;
