import { componentMap, ComponentMap } from './TransactionTypeMap';

/**
Higher-order component that takes in a mapping of balanceChange types to their corresponding transaction components, and the netBalanceChanges array
from a simulation as inputs. Each balance change is mapped to a component and its props to dynamically construct the transactions from a given simulation.
*/
const createFromGeneric = (componentMap: ComponentMap) => {
  return (netBalanceChanges: any[]) => {
    return (
      <div className="space-y-4">
        {netBalanceChanges.map((item, index) => {
          const componentType = item.type;

          const Component = componentMap.get(componentType)?.component;

          //If the component couldn't be found, this is likely a problem. To not crash the app, log and return null so other components can render
          if (!Component) {
            console.error('TransactionFactory: Component not found');
            return null;
          }

          //If the component accepts props, this sends the balanceChange to its constructor
          const propsConstructor =
            componentMap.get(componentType)?.propsConstructor;
          const props = propsConstructor ? propsConstructor(item) : {};

          return <Component key={index} {...props} />;
        })}
      </div>
    );
  };
};

const TransactionFactory = createFromGeneric(componentMap);

export default TransactionFactory;
