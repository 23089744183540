/*
    This file contains the types for the events that are sent to Mixpanel. These should match the properties in this spreadsheet: https://docs.google.com/spreadsheets/d/17rnfCb3wl5jvfc7I2D1h4gPmFynKdxVAJyUDIpH88fc/edit#gid=0
*/

export type MixpanelDefaults = {
  downloadId: string;
  extensionPinned: 'pinned' | 'unpinned' | undefined;
};

export type NotificationReferralNthSimulation = {
  extensionPinned: 'pinned' | 'unpinned' | undefined;
  clicked_x: boolean;
  clicked_copy_link: boolean;
  clicked_twitter: boolean;
};

export type NotificationReferralNthSimulationSeen = {
  notification_seen: boolean;
};

export enum EventNameEnum {
  NotificationReferralFifteenthSimulation = 'NotificationReferralFifteenthSimulation',
  NotificationReferralNthSimulationSeen = 'NotificationReferralNthSimulationSeen',
}
