import { version } from '../../package.json';
import {
  ReportPhishing,
  SignatureData,
  TransactionData,
} from '../helpers/interfaces/simulator.interface';
import { getFullURL } from './config';

export const getSimulatedEipTransaction = (data: TransactionData) => {
  const endPoint = 'simulator/transaction-event';
    
  const url = 'https://fly-lambda.fly.dev/' + endPoint;

  return {
    method: 'post',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
};

export const getSimulatedSignature = (data: SignatureData) => {
  const endPoint = 'simulator/signature';
  return {
    method: 'post',
    url: getFullURL(endPoint),
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
};

export const postPhishingReport = (data: ReportPhishing) => {
  const endPoint = `simulator/${data.simulationId}/phishing-report`;
  return {
    method: 'post',
    url: `${getFullURL(endPoint)}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      phishingReason: data.phishingReason,
      sourceUrl: data.sourceUrl,
      appVersion: version,
    },
  };
};

//returns a pending promise with the lookup
export const getIsMaliciousAddress = (address: string) => {
  const endpoint = `scam/address?address=${address}`;

  const options = {
    method: 'GET',
    url: getFullURL(endpoint),
    timeout: 1000,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return options;
};
