import React from 'react';
import ReferralLinkButton from './ReferralLinkButton';
import { ReferralData } from '../../helpers/types/referrals';

interface IReferralCardProps {
  data: ReferralData | undefined;
  loading: boolean;
  error: any;
  onReferralButtonClick?: () => void;
}

const ReferralCard: React.FC<IReferralCardProps> = ({
  data,
  loading,
  error,
  onReferralButtonClick,
}) => {
  const disabled = loading || error;
  return (
    <div className="bg-secondary-800 h-52 w-[331px] rounded-lg p-7 mx-auto">
      <div className="font-Manrope text-white text-center text-sm">
        Your referral code:
      </div>
      <div className="font-ClashDisplay text-white align-middle mt-4 text-center text-4xl tracking-wider font-semibold">
        {!loading && data ? data.referralId : 'XXXXXXXXX'}
      </div>
      <ReferralLinkButton
        onClickOverride={onReferralButtonClick}
        loading={loading}
        disabled={disabled}
        link={!loading && data ? data.referralLink : ''}
      />
    </div>
  );
};

export default ReferralCard;
