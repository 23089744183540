import { NETWORK_INFO } from '../../helpers/constants/application.constants';
import { BackArrow } from '../../helpers/icons/icons';
import { EIPGlobalInformation } from '../../helpers/interfaces/dataTypes.interface';
import FireButton from '../Shared/Buttons/FireButton';

interface IReportContractModalContent {
  txDetail?: EIPGlobalInformation;
  modalIcon?: string | JSX.Element;
  header: string;
  subHeader: string;
  isScam: boolean;
  selectedReason: string;
  useBackBtn?: boolean;
  onBackBtnClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

//TODO: Much of this file is commented out until we return to image sharing
const ShareSimulation = ({ ...props }: IReportContractModalContent) => {
  const urlEncode = (input: string) => {
    return input.replace(/ /g, '%20');
  };

  const linkText = urlEncode(`Download the extension to keep yourself safe:`);
  const url = 'https://www.joinfire.xyz/?utm_source=sharescam';

  let text: string;
  let reportedText: string;
  let tweetMessage: string;

  if (props.txDetail) {
    text = urlEncode(`I was just alerted of a scam by @_joinfire.`);
    reportedText = urlEncode(
      `I reported ${props.txDetail.spender ? 'address' : 'contract'}: ${
        props.txDetail.spender || props.txDetail.contractAddress
      } on ${
        NETWORK_INFO[props.txDetail.network].network
      } because it's ${props.selectedReason.toLowerCase()}.`
    );
    tweetMessage = `${text}%0A%0A${reportedText}%0A%0A%0A${linkText}&url=${url}`;
  } else {
    text = urlEncode(`@joinfire just warned me of a wallet drainer.`);
    tweetMessage = `${text}%0A%0A${linkText}&url=${url}`;
  }

  return (
    <div className="h-auto w-[280px] mx-auto py-4">
      {props.modalIcon ? (
        <div className="w-full flex justify-center mb-5">{props.modalIcon}</div>
      ) : props.useBackBtn ? (
        <div className="w-full flex justify-start mb-2">
          <button className="px-3" onClick={(e) => props.onBackBtnClick?.(e)}>
            {BackArrow}
          </button>
        </div>
      ) : (
        <></>
      )}
      <div className="text-bg-fire_purple-900 text-center text-2xl px-10 font-ClashDisplay font-medium mb-5">
        {props.header}
      </div>
      <div className="grid">
        <div className="space-y-1 flex flex-col justify-center">
          <span className="text-bg-fire_purple font-Inter mb-2 mx-auto font-light">
            {props.subHeader}
          </span>
          <div className="w-fit mx-auto">
            <div className="mb-1">
              <span className="text-bg-fire_purple font-Inter font-light">
                1. Take a screenshot of your simulation
              </span>
            </div>
            <span className="text-bg-fire_purple font-Inter font-light">
              2. Press share and paste your image!
            </span>
          </div>
        </div>
        <FireButton
          className="mt-5 mb-5 mx-auto w-[150px]"
          onClick={() => {
            window.open(
              `https://twitter.com/intent/tweet?text=${tweetMessage}`
            );
          }}
        >
          Share
        </FireButton>
      </div>
    </div>
  );
};

export default ShareSimulation;
