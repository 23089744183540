import ReportContractModalContent from '../ReportContract/ReportContractModalContent';
import FireModal from '../Shared/FireModal/FireModal';
import { EIPGlobalInformation } from '../../helpers/interfaces/dataTypes.interface';

interface IReportContractModalProps {
  eipGlobalInformation: EIPGlobalInformation;
}

const ReportContractModal: React.FC<IReportContractModalProps> = ({
  eipGlobalInformation,
}: IReportContractModalProps) => {
  return (
    <FireModal
      width={315}
      modalContent={
        <ReportContractModalContent txDetail={eipGlobalInformation} />
      }
    >
      <div
        style={{ bottom: 0 }}
        className="w-full fixed bottom-0 h-7 bg-white border border-top align-middle content-center flex justify-center"
      >
        <button className="text-center font-extralight text-sm underline text-slate hover:cursor-pointer">
          🚩Report a malicious contract
        </button>
      </div>
    </FireModal>
  );
};

export default ReportContractModal;
