import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { useState } from 'react';
import { ScamTypesEnum } from '../../helpers/enums/application.enums';
import { exclamation_triangle_filled } from '../../helpers/icons/icons';
import { ScamDetailMapping } from '../../helpers/interfaces/dataTypes.interface';
import WarningBanner from '../Banner/WarningBanner';
import OverlayFilter from '../Overlays/OverlayFilter';
import TopOverlay from '../Overlays/TopOverlay';
import Drawer from '../Shared/Drawer/Drawer';
import ScamWarning from './ScamWarning';

const ScamWarningDrawer: React.FC = () => {
  const [modalVisible, setModalVisible] = useState(true);
  return (
    <>
      <AnimatePresence>
        {modalVisible && (
          <TopOverlay>
            <OverlayFilter>
              <Drawer visible={modalVisible} setVisible={setModalVisible}>
                <>
                  <WarningBanner
                    title={
                      ScamDetailMapping[ScamTypesEnum.MALICIOUS_ADDRESS]
                        .warningHeader
                    }
                    icon={exclamation_triangle_filled('h-[50px] w-[50px] mb-1')}
                  />
                  <ScamWarning
                    scamDetail={
                      ScamDetailMapping[ScamTypesEnum.MALICIOUS_ADDRESS]
                    }
                  />
                </>
              </Drawer>
            </OverlayFilter>
          </TopOverlay>
        )}
      </AnimatePresence>
    </>
  );
};

export default ScamWarningDrawer;
