import { ReferralData } from '../helpers/types/referrals';
import { getFullURL } from './config';

export const getReferralData = async (
  downloadId: string
): Promise<ReferralData | undefined> => {
  try {
    const res = await fetch(getFullURL(`referrals/download/${downloadId}`));
    return (await res.json()) as ReferralData;
  } catch (e) {
    console.error('Error Caught:', e);
    return undefined;
  }
};
