import ExitIcon from '../Shared/FireModal/ExitIcon';

interface IDataContentProps {
  onClick: React.MouseEventHandler<Element>;
  data: string;
}

const DataContent = ({ onClick, data }: IDataContentProps) => {
  return (
    <div className="w-full h-[525px] bg-white rounded-xl border-t shadow-lg">
      <div className="flex flex-row justify-between border-b-2 border-b-gray-200 h-12 items-center p-2">
        <div className="h-full w-fit text-2xl font-ClashDisplay underline underline-offset-[12px] text-secondary-800 decoration-secondary-800 decoration-2 font-medium">
          Data
        </div>
        <ExitIcon onClick={onClick} />
      </div>
      <div className="p-2 h-full w-full">
        <div className="h-[475px] w-full bg-gray-200 rounded-md p-4 break-all overflow-y-auto mb-2 py-6">
          {data.charAt(0) === '{' ? <pre>{data}</pre> : data}
        </div>
      </div>
    </div>
  );
};

export default DataContent;
