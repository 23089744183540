import SlideUpAnimation from '../Animations/SlideUpAnimation';
import ExitIcon from '../FireModal/ExitIcon';

interface DrawerProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  title?: JSX.Element | string;
}

const Drawer: React.FC<DrawerProps> = ({
  visible,
  setVisible,
  children,
  title,
}) => {
  return (
    <SlideUpAnimation trigger={visible}>
      <div
        className={
          'h-auto w-full rounded-t-xl border-t bg-white isolate relative overflow-hidden'
        }
      >
        <div className="flex flex-row justify-center p-5 absolute top-0 w-full z-50">
          <div className="w-full">{title}</div>
          <ExitIcon onClick={() => setVisible(false)} />
        </div>
        <div>{children}</div>
      </div>
    </SlideUpAnimation>
  );
};

export default Drawer;
