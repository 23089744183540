import React from 'react';
import { useState } from 'react';

interface IReferralButtonProps {
  loading?: boolean;
  disabled?: boolean;
  link: string;
  onClickOverride?: () => void;
}

export const ReferralLinkButton: React.FC<IReferralButtonProps> = ({
  ...props
}) => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleButtonClick = () => {
    props.onClickOverride && props.onClickOverride();
  };

  return (
    <button
      onClick={() => {
        handleButtonClick();
        navigator.clipboard.writeText(props.link);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 500);
      }}
      disabled={props.loading || props.disabled}
      className={`w-[274px] h-12 rounded-full ${
        copied && 'scale-100'
      } font-ClashDisplay font-medium tracking-wider text-lg text-secondary-800 mt-7 transition ease-in-out ${
        !copied && !props.loading && !props.disabled && 'hover:scale-105'
      } duration-300`}
      style={{
        background:
          'linear-gradient(268.6deg, rgba(225, 207, 255) 22.25%, rgba(247, 172, 107) 103.25%)',
      }}
    >
      {copied ? 'Link Copied!' : 'Copy my link'}
    </button>
  );
};

export default ReferralLinkButton;
