import { useState, useMemo } from 'react';
import { getAddressLabel } from '../helpers/methods';

export const useAddressLabels = (
  addressList: string[],
  network: string,
  existingMapping?: Map<string, Promise<string | null>>,
) => {
  const addressSet = new Set<string>(addressList);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Map<string, string | null>>();
  const [error, setError] = useState<unknown | undefined>(undefined);

  useMemo(() => {
    setLoading(true);
    const getAddressLabelMap = async () => {
      try {
        // grab address labels for each address in the set and return a promise
        const addressSetArray = Array.from(addressSet);

        let promisesToSettle: Promise<string | null>[];

        //If we have lookups already in progress in the existingMapping, merge these promises in to resolve with the others
        if (existingMapping) {
          //extract pending lookups and their mapped addresses as arrays from the map
          const pendingLookups = Array.from(existingMapping.values());
          const existingMappingAddresses = Array.from(existingMapping.keys());

          //Remove pending promises from the lookups
          const lookupAddresses = addressSetArray.filter(
            (address: string) => !existingMappingAddresses.includes(address),
          );

          //merge the already pending promises with our lookups
          promisesToSettle = [
            ...lookupAddresses.map((address: string) =>
              getAddressLabel(address, network),
            ),
            ...pendingLookups,
          ];
        } else {
          promisesToSettle = addressSetArray.map((address: string) =>
            getAddressLabel(address, network),
          );
        }

        const settledPromises: PromiseSettledResult<string | null>[] =
          await Promise.allSettled(promisesToSettle);

        //Employ a type guard to see which promises fulfilled and convert the type accordingly
        const isFilled = <T extends string | null>(
          promiseToSettle: PromiseSettledResult<T>,
        ): promiseToSettle is PromiseFulfilledResult<T> =>
          promiseToSettle.status === 'fulfilled';

        //If the promise resolved then map its value, if it failed map null
        const addressLabels: (string | null)[] = settledPromises.map(
          (promise: PromiseSettledResult<string | null>) =>
            isFilled(promise) ? promise.value : null,
        );

        //Return a mapping of addresses and labels if returned
        const labeledAddressMap = new Map<string, string | null>(
          Array.from(addressSet).map((address: string, i: number) => [
            address,
            addressLabels[i],
          ]),
        );

        setData(labeledAddressMap);
        setError(undefined);
        setLoading(false);
      } catch (e) {
        //If an uncaught error occurred, log and return empty map
        console.error(e);
        const labeledAddressMap = new Map<string, string | null>(
          Array.from(addressSet).map((address) => [address, null]),
        );

        setData(labeledAddressMap);
        setError(e);
        setLoading(false);
      }
    };
    getAddressLabelMap();
  }, []);

  return { data, loading, error };
};
