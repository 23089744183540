interface IEmojiIcon {
  emoji: string;
  size: 'sm' | 'md' | 'lg';
}

const EmojiIcon: React.FC<IEmojiIcon> = ({ emoji, size }: IEmojiIcon) => {
  //TODO: Size these other variants
  const variants = {
    md: {
      size: 'min-h-[42px] min-w-[42px] text-[22px]',
    },
    sm: {
      size: 'h-[42px] w-[42px]',
    },
    lg: {
      size: 'h-[42px] w-[42px]',
    },
  };

  return (
    <div
      className={`${variants[size].size} rounded-full bg-secondary-800 flex justify-center items-center`}
    >
      {emoji}
    </div>
  );
};

export default EmojiIcon;
