import FireLogo from './FireLogo';

interface IHeaderProps {
  className?: string;
  useHeader?: boolean;
}

const Header: React.FC<IHeaderProps> = ({ className, useHeader }) => {
  return useHeader ? (
    <nav className={`${!!className && className} bg-white h-[64px]`}>
      <div className="flex flex-row justify-center content-center items-center h-full">
        <div className="mb-2">
          <FireLogo />
        </div>
        <div
          className="text-center cursor-pointer font-ClashDisplay text-secondary-800 font-semibold px-2 tracking-wider"
          style={{ fontSize: '31px' }}
          onClick={() => window.open('https://www.joinfire.xyz/')}
        >
          FIRE
        </div>
      </div>
    </nav>
  ) : (
    <></>
  );
};

export default Header;
