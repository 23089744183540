import {
  DeepSimulationType,
  EIPTransactionTypes,
  ErrorTypes,
  ScamTypesEnum,
} from '../enums/application.enums';

export interface DexSwapDetail {
  type: string;
  token: string;
  amount: number;
  purpose?: string;
  to: string;
  ensTo?: string;
  decimals?: number;
  verified: boolean;
  expiration?: Date;
  usdValue?: number;
}
export interface EIPGlobalInformation {
  network: string;
  from: string;
  contractAddress: string;
  type: EIPTransactionTypes | string;
  transactionAction: string;
  loading: boolean;
  error: ISimulationError | undefined;
  trustedContract?: string | boolean;
  walletProvider?: string;
  downloadId?: string;
  downloadUrl?: string;
  sourceUrl?: string;
  browser?: string;
  simulationId: string;
  spender?: string;
  deepSimulationData?: DeepSimulation;
  addressLabels?: Map<string, Promise<string | null>>;
  rawData: string;
  isFireWalletSimulation?: boolean;
  payload?: TransactionSimulationRequest;
  primaryType?: string | undefined;
  isMaliciousAddress: boolean | undefined;
  extensionPinned?: 'pinned' | 'unpinned' | undefined;
}

//data or transaction will both contain the params of the request, are unfortunately named differently in tx vs sig reqs :(
//TODO: refactor the strucutre of the request on the simulator side to match fire-wallet's
interface RpcPayload {
  type?: string;
  data?: any;
  transaction?: any;
}

export interface TransactionSimulationRequest extends RpcPayload {
  downloadId: string;
  downloadUrl: string;
  extensionPinned: 'pinned' | 'unpinned';
  sourceUrl: string;
  walletProvider: string;
  network?: string;
  isMaliciousAddress: boolean | undefined;
}

export interface SignatureSimulationRequest extends RpcPayload {
  types: any;
  primaryType: string;
  domain: string;
  message: any;
  fromAddress: string;
  event: { name: string; version: string };
  browser: string;
  simulationType: string;
  downloadId: string;
  downloadUrl: string;
  extensionPinned: 'pinned' | 'unpinned';
  sourceUrl: string;
  walletProvider: string;
  network?: string;
}

export interface SimulationData {
  type: string;
  from: string;
  to: string;
  signature?: boolean;
  netBalanceChanges: {
    type: string;
    to: string;
    from: string;
    value: string | number;
    token?: {
      decimals: number;
      name: string;
      symbol: string;
      verified: boolean;
      logo?: string;
      contractAddress?: string;
      imageUrl?: string;
      verifiedCollection?: boolean;
    };
    contract?: string;
    usdValue?: number;
    id?: string;
    spender?: string;
    contractAlias?: string;
  }[];
  deepSimulationData?: DeepSimulation;
  id?: string;
}

export interface DeepSimulation {
  data: ENSRegister | Philand | SignatureRequest | PolygonNativeBridge;
  type: DeepSimulationType;
}

export interface ENSRegister {
  name: string;
}

export interface Philand {
  name: string;
}

export interface SignatureRequest {
  from: string;
  purpose: string;
}

export interface PolygonNativeBridge {
  address: string;
  value: number;
  fromNetwork: string;
  toNetwork: string;
  trusted: boolean;
}

export interface ISharedSimulationProps {
  children: JSX.Element;
  txDetail: EIPGlobalInformation;
  header: string;
  action: string;
}

export interface ISimulationError {
  errorIcon: JSX.Element | string;
  title: ErrorTypes;
  messageComponent: JSX.Element;
}

export interface IErc721ApproveDetail {
  type: 'In' | 'Out';
  purpose: string;
  to: string;
  from: string;
  metadata: IErc721ApproveMetadata;
  id: string | number | null;
  token: string;
  amount: string;
}

interface IErc721ApproveMetadata {
  name: string;
  contractAddress: string;
  imageUrl: string;
}

export interface ISignatureDescription {
  icon: JSX.Element;
  description: string;
}

export interface ISignatureDetail {
  isSafe: boolean;
  descriptionList: ISignatureDescription[];
}

export interface IRawERC20BalanceChange {
  type: BalanceChangeTypeEnum;
  to: string;
  from: string;
  value: number;
  token: IERC20TokenMetadata;
  usdValue: number;
}

export interface IERC20TokenMetadata {
  decimals: number;
  logo: string;
  name: string;
  symbol: string;
  verified: boolean;
}

export interface IERC20BalanceChange {
  type: BalanceChangeTypeEnum;
  to: string;
  value: number | 'Infinite' | '< 0.001';
  usdValue: string | undefined;
  logo: string;
  symbol: string;
  verified: boolean;
  expiration?: string;
}

export enum BalanceChangeTypeEnum {
  ERC20 = 'erc20',
  ETH = 'eth',
}

export interface ERC1155Data {
  type: string;
  id: string;
  to: string;
  from: string;
  contract: string;
  value: string;
  token: {
    name: string;
    contractAddress: string;
    imageUrl: string;
    verifiedCollection: boolean;
  };
}

export interface ScamDetail {
  warningHeader: string;
  warningDetail: DescriptionRow[];
}

interface DescriptionRow {
  emojiLabel: string;
  description: string;
}

export type ScamDetailMapping = {
  [Key in keyof typeof ScamTypesEnum as string]: ScamDetail;
};

export const ScamDetailMapping: ScamDetailMapping = {
  [ScamTypesEnum.MALICIOUS_ADDRESS]: {
    warningHeader: 'Malicious Address',
    warningDetail: [
      {
        emojiLabel: '🚨',
        description:
          'Fire believes this address is malicious. We strongly recommend rejecting this transaction.',
      },
      {
        emojiLabel: '💸',
        description:
          'Signing this transaction could lead to a complete loss of funds.',
      },
      {
        emojiLabel: '🙏',
        description:
          'Proceed with this transaction ONLY if you completely trust this address.',
      },
    ],
  },
  [ScamTypesEnum.MALICIOUS_SIGNATURE]: {
    warningHeader: 'Dangerous Signature',
    warningDetail: [
      {
        emojiLabel: '✍️',
        description:
          'This dApp is requesting a signature type frequently associated with scams.',
      },
      {
        emojiLabel: '💸',
        description:
          'Signing this transaction allows this site to execute an arbitrary transaction on your behalf.',
      },
      {
        emojiLabel: '‼️',
        description:
          'Proceed with this transaction ONLY if you completely trust this dApp.',
      },
    ],
  },
};
