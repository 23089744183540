import { version } from '../../../package.json';
import { captureGenericEvent } from '../../api/events';
import {
  EventNameEnum,
  MixpanelDefaults,
  NotificationReferralNthSimulation,
} from '../../helpers/types/events';

export const trackNotificationEvent = async (
  mixpanelDefaults: MixpanelDefaults,
  event: NotificationReferralNthSimulation
) => {
  await captureGenericEvent(
    mixpanelDefaults.downloadId,
    EventNameEnum.NotificationReferralFifteenthSimulation,
    {
      ...event,
      extension_pinned: mixpanelDefaults.extensionPinned,
      version,
    }
  );
};

//mixpanel event tracking
export const trackNotificationSeenEvent = async (
  mixpanelDefaults: MixpanelDefaults
) => {
  await captureGenericEvent(
    mixpanelDefaults.downloadId,
    EventNameEnum.NotificationReferralNthSimulationSeen,
    {
      notification_seen: true,
      extension_pinned: mixpanelDefaults.extensionPinned,
      notification_type: EventNameEnum.NotificationReferralFifteenthSimulation,
      version,
    }
  );
};

export const trackClickedTwitterEvent = async (
  notificationReferralNthSimulation: NotificationReferralNthSimulation,
  mixpanelDefaults: MixpanelDefaults
) => {
  await trackNotificationEvent(mixpanelDefaults, {
    ...notificationReferralNthSimulation,
    clicked_twitter: true,
  });
};

export const trackExitClickEvent = async (
  notificationReferralNthSimulation: NotificationReferralNthSimulation,
  mixpanelDefaults: MixpanelDefaults
) => {
  await trackNotificationEvent(mixpanelDefaults, {
    ...notificationReferralNthSimulation,
    clicked_x: true,
  });
};

export const urlEncode = (input: string) => {
  return input.replace(/&/g, '%26');
};
