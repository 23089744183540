import 'semantic-ui-css/semantic.css';
import * as dotenv from 'dotenv';
import './styles/css/index.css';
import App from './App';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
// If taking advantage of automatic instrumentation (highly recommended)
import { BrowserTracing } from '@sentry/tracing';

dotenv.config();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  // This enables automatic instrumentation (highly recommended), but is not
  // necessary for purely manual usage
  integrations: [new BrowserTracing()],
  // 100% of transactions tracked
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);
