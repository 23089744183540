import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import React, { useEffect, useRef } from 'react';
import SlideUpAnimation from '../Animations/SlideUpAnimation';
import ExitIcon from '../FireModal/ExitIcon';
import OverlayFilter from '../../Overlays/OverlayFilter';

interface IDrawerProps {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title?: JSX.Element;
  children: React.ReactNode;
  onExitModal?: () => void;
}

const Drawer: React.FC<IDrawerProps> = ({
  title,
  visible,
  setVisible,
  children,
  onExitModal,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  //Listen for a click outside the popup to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setVisible(false);
        onExitModal && onExitModal();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setVisible]);

  return (
    <AnimatePresence>
      {visible && (
        <OverlayFilter>
          <SlideUpAnimation trigger={visible}>
            <div
              className={
                'relative isolate h-auto w-full overflow-hidden rounded-t-xl border-t bg-white'
              }
              ref={ref}
            >
              <div className="absolute top-0 z-50 flex w-full flex-row justify-center p-5">
                <div className="w-full">{title}</div>
                <ExitIcon
                  onClick={() => {
                    setVisible(false);
                    onExitModal && onExitModal();
                  }}
                />
              </div>

              <div>{children}</div>
            </div>
          </SlideUpAnimation>
        </OverlayFilter>
      )}
    </AnimatePresence>
  );
};

export default Drawer;
