import { buildPhilandTransactionSummaryFromSimulation } from '../../components/Simulation/DeepSimulations/Philand/PhilandTransactionSummary';
import { NETWORK_INFO } from '../constants/application.constants';
import {
  DeepSimulationType,
  EIPTransactionTypes,
  philandDeepSimulationType,
} from '../enums/application.enums';
import { formatCurrency, getContractName, reduceAddress } from '../methods';
import {
  DeepSimulation,
  DexSwapDetail,
  PolygonNativeBridge,
  SimulationData,
} from './../interfaces/dataTypes.interface';

export const buildApprovalMessage = (simulation: any, network: string) => {
  const transactionAction = ['0', 'none'].includes(
    simulation.netBalanceChanges[0].value
  )
    ? 'revoke'
    : 'allow';
  const spender = simulation.spender;
  const contractAlias = simulation.contractAlias;

  const activeChainMessage = NETWORK_INFO[network]
    ? ` on the ${NETWORK_INFO[network].network}`
    : '';

  const bannerMessage = [
    {
      bold: false,
      text: `This transaction would ${transactionAction} approval for your `,
    },
    {
      bold: true,
      text: `${contractAlias} to  ${
        getContractName(spender, network) ||
        reduceAddress(spender ?? simulation.to)
      }`,
    },
    {
      bold: false,
      text: activeChainMessage,
    },
  ];

  return bannerMessage;
};

export const buildTransactionMessage = (
  simulation: SimulationData,
  isSignature: boolean,
  network: string,
  primaryType: string = ''
) => {
  const erc20 = getErc20Txs(
    simulation?.from ?? '',
    simulation?.netBalanceChanges ?? []
  );

  const erc721 = getErc721Txs(
    simulation?.from ?? '',
    simulation?.netBalanceChanges ?? []
  );

  let message;

  if (
    simulation.deepSimulationData &&
    philandDeepSimulationType.includes(simulation.deepSimulationData?.type)
  ) {
    message = buildPhilandTransactionSummaryFromSimulation(
      simulation,
      isSignature,
      network,
      erc20,
      erc721
    );
    return message;
  }

  message = buildTransactionSummary(
    [...erc20, ...erc721],
    isSignature,
    erc20,
    erc721,
    network,
    simulation.to,
    undefined,
    primaryType
  );

  return message;
};

export const buildBannerMessage = (
  transactionType: string,
  simulation: SimulationData,
  isSignature: boolean,
  network: string,
  primaryType: string = ''
) => {
  if (transactionType.includes('Approve')) {
    return buildApprovalMessage(simulation, network);
  } else {
    return buildTransactionMessage(
      simulation,
      isSignature,
      network,
      primaryType
    );
  }
};

export const transactionSummary = (type: string, allTxs: any): string[] => {
  return allTxs
    .filter((transaction: any) => transaction.type === type)
    .map((transaction: any) => {
      if (['transfer'].includes(transaction.purpose.toLowerCase())) {
        return (
          formatCurrency(transaction.amount, transaction.decimals) +
          ' ' +
          transaction.token
        );
      } else if (
        ['erc721', 'erc1155'].includes(transaction.purpose.toLowerCase())
      ) {
        return transaction.token;
      } else return '';
    })
    .filter((summary: string) => summary !== '');
};

export const formatTransactionSummary = (
  transactionSummaryArray: string[]
): string => {
  return transactionSummaryArray.length === 1
    ? transactionSummaryArray[0]
    : transactionSummaryArray.join(', ');
};

export const getErc20Txs = (
  walletAddress: string,
  netBalanceChanges: any[]
): DexSwapDetail[] => {
  return netBalanceChanges
    .filter(
      (balanceChange) =>
        ![EIPTransactionTypes.ERC721, EIPTransactionTypes.ERC1155].includes(
          balanceChange.type
        )
    )
    .map((transaction: any) => {
      let purpose;
      if (transaction.to === 'validators') {
        purpose = 'Gas';
      } else {
        purpose = 'Transfer';
      }
      const swapDetail: DexSwapDetail = {
        token: transaction.token?.symbol,
        decimals: transaction.token?.decimals,
        amount: transaction.value,
        type: transaction.from === walletAddress ? 'Out' : 'In',
        purpose: purpose,
        to: transaction.to,
        expiration: transaction.expiration,
        ensTo: transaction.ensTo,
        verified: transaction.token?.verified,
        usdValue:
          typeof transaction.usdValue === 'number' &&
          transaction.usdValue !== -1
            ? transaction.usdValue
            : -1,
      };

      return swapDetail;
    });
};

export const getErc721Txs = (
  walletAddress: string,
  netBalanceChanges: any[]
): DexSwapDetail[] => {
  return netBalanceChanges
    .filter((balanceChange) =>
      [EIPTransactionTypes.ERC721, EIPTransactionTypes.ERC1155].includes(
        balanceChange.type
      )
    )
    .map((transaction: any) => {
      const swapDetail: any = {};
      swapDetail.type = transaction.from === walletAddress ? 'Out' : 'In';
      swapDetail.purpose = transaction.type;
      swapDetail.to = transaction.to;
      swapDetail.from = transaction.from;
      swapDetail.ensTo = transaction.ensTo;
      swapDetail.ensFrom = transaction.ensFrom;
      swapDetail.metadata = transaction.token;
      swapDetail.id = transaction.id;
      swapDetail.token = transaction.token?.name;
      swapDetail.amount = transaction.value;

      return swapDetail;
    });
};

export const buildTransactionSummary = (
  allTxs: any,
  signature: boolean | undefined,
  dexSwap: DexSwapDetail[],
  erc721: any,
  network: string,
  to: string,
  deepSimulationData: DeepSimulation | undefined,
  primaryType: string = ''
) => {
  const allTransfers = allTxs.filter(
    (transaction: any) => transaction.to !== 'validators'
  );

  const outgoingTransactionSummary = transactionSummary('Out', allTxs);
  const incomingTransactionSummary = transactionSummary('In', allTxs);
  const formattedOutgoingTransactionSummary = formatTransactionSummary(
    outgoingTransactionSummary
  );
  const formattedIncomingTransactionSummary = formatTransactionSummary(
    incomingTransactionSummary
  );

  let message;

  if (signature && primaryType === 'SafeTx') {
    message = ' authorize a transaction on a Gnosis Safe';
  } else if (
    [
      DeepSimulationType.POLYGON_NATIVE_BRIDGE_ERC20,
      DeepSimulationType.POLYGON_NATIVE_BRIDGE_ETH,
      DeepSimulationType.POLYGON_NATIVE_BRIDGE_WITHDRAW,
    ].includes(deepSimulationData?.type as any)
  ) {
    message = ` transfer ${formattedIncomingTransactionSummary} from the ${
      NETWORK_INFO[
        (deepSimulationData?.data as PolygonNativeBridge).fromNetwork
      ].network
    } to the ${
      NETWORK_INFO[(deepSimulationData?.data as PolygonNativeBridge).toNetwork]
        .network
    }`;
  } else if (!signature) {
    const receipients = [...dexSwap, ...erc721].find(
      (tx) => tx.to !== 'validators'
    );
    const to = receipients ? receipients.to : [...dexSwap, ...erc721][0].to;
    const ensTo = receipients
      ? receipients.ensTo
      : [...dexSwap, ...erc721][0].ensTo;
    message =
      allTransfers.filter((tx: any) => tx.type === 'In').length > 0 &&
      allTransfers.filter((tx: any) => tx.type === 'Out').length > 0
        ? ` swap ${formattedOutgoingTransactionSummary} for ${formattedIncomingTransactionSummary} `
        : allTransfers.filter((tx: any) => tx.type === 'In').length > 0
        ? ` transfer ${formattedIncomingTransactionSummary} to you `
        : ` transfer ${formattedOutgoingTransactionSummary} to ${
            ensTo ?? reduceAddress(to)
          }`;
  } else {
    message = ` allow ${reduceAddress(
      to
    )} to swap ${formattedOutgoingTransactionSummary} for ${formattedIncomingTransactionSummary} `;
  }

  const transactionType = signature ? 'signature' : 'transaction';

  const activeChainMessage =
    NETWORK_INFO[network] &&
    ![
      DeepSimulationType.POLYGON_NATIVE_BRIDGE_ERC20,
      DeepSimulationType.POLYGON_NATIVE_BRIDGE_ETH,
      DeepSimulationType.POLYGON_NATIVE_BRIDGE_WITHDRAW,
    ].includes(deepSimulationData?.type as any)
      ? ` on the ${NETWORK_INFO[network].network}`
      : '';

  return [
    {
      bold: false,
      text: `This ${transactionType} would`,
    },
    {
      bold: true,
      text: message,
    },
    {
      bold: false,
      text: activeChainMessage,
    },
  ];
};
