import { useState } from 'react';
import DataContent from '../DataTab/DataContent';
import ViewRawButton from '../DataTab/ViewRawButton';
import SlideUpAnimation from './Animations/SlideUpAnimation';

interface IWalletEffectsProps {
  className?: string;
  data: string | undefined;
  loading: boolean;
  isSimulation?: boolean;
}

const WalletEffectsHeader: React.FC<IWalletEffectsProps> = ({
  className,
  data,
  loading,
  isSimulation = true,
}) => {
  const title = isSimulation ? 'Wallet Effects' : 'Interaction Info';
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <div className="flex justify-between w-[371px] mt-5 mx-auto px-5 relative z-50">
      <div className="flex justify-between items-center w-full">
        <div className={`${className} font-ClashDisplay text-2xl font-medium`}>
          {title}
        </div>
        <div>
          <ViewRawButton
            onClick={() => setVisible(!visible)}
            disabled={loading}
          />
          <SlideUpAnimation trigger={visible}>
            <DataContent
              data={data ?? ''}
              onClick={() => setVisible(!visible)}
            />
          </SlideUpAnimation>
        </div>
      </div>
    </div>
  );
};

export default WalletEffectsHeader;
